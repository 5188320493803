





































































































































































































































































































































































































import CFMDialog from '@/scripts/CFMDialog'
import { Vue, Component, Prop } from 'vue-property-decorator'

import * as blockui from '@/helpers/block-ui'

import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import BootstrapSwitchComponent from '@/components/shared/BootstrapSwitchComponent'
import MinutePicker from '@/components/shared/minutepicker.vue'

import * as dateFormat from '@/helpers/dateFormat'
import * as swal from '@/helpers/swal'

import { required, requiredIf, minValue } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import {
  CFMDTOWorkOrder,
  CFMCoreEntityComboSource,
  CFMDTOWorkOrderTraining,
  CFMDTOWorkForMaintenancePlan,
  CFMModelSuitableWorkUserSP,
} from '@/scripts/services/ClientApiAuto'
import { mixinz } from '@/scripts/global/mixinz'

@Component<TrainingAsignmentModal>({
  data() {
    return {
      selectedTrainingPeriod: 6,
      value: 1,
      selectedMonthlyDays: [],
      monthlyDaysList: Array.from({ length: 31 }, (_, index) => ({ id: index + 1, value: index + 1 })),
      selectedDays: [],
      repeatedDays: 1,
      addOrEdit: true,
      closeOnselect: false,
    }
  },

  methods: {
    selectedTraining() {
      this.$v.$reset()
      this.repeatedDays = 1
    },
  },

  components: {
    'cfm-multiselect': CFMMultiselect,
    'vue-multiselect': Multiselect,
    'bootstrap-switch-component': BootstrapSwitchComponent,
    minutepicker: MinutePicker,
  },

  validations() {
    return {
      startDate: { required },
      maintenancePlan: {
        OrderTypeId: {
          required,
        },
        Duration: {
          required,
          minValue: minValue(1),
        },
        VendorId: {},
      },
      workId: {
        // @ts-ignore
        requiredIf: requiredIf(() => !this.isUpdate),
      },
      selectedDays: {
        // @ts-ignore
        requiredIf: requiredIf(() => this.selectedTrainingPeriod == 5 && !this.isUpdate),
      },
      selectedMonthlyDays: {
        // @ts-ignore
        requiredIf: requiredIf(() => this.selectedTrainingPeriod == 1 && !this.isUpdate),
      },
      maintenanceCount: {
        // @ts-ignore
        requiredIf: requiredIf(() => !this.isUpdate),
        minValue: (value: any) => this.isUpdate || value > 0,
      },
      AssignedIds: {
          required,
        },
    }
  },
  mixins: [mixinz],
})
export default class TrainingAsignmentModal extends CFMDialog {
  [x: string]: unknown
  @Prop()
  defaultStartDate?: null | Date
  @Prop()
  deleteBtnShow?: null | boolean
  maintenancePlan: CFMDTOWorkOrder = { Name: '', Duration: 0, DeviceId: null }

  selectedTraining: any
  value: null | number = null
  fullWorkList: CFMDTOWorkForMaintenancePlan[] = []
  selectedDays: number[] = []
  repeatedDays: null | number = null
  selectedMonthlyDays: number[] = []
  workList: CFMCoreEntityComboSource[] = []
  vendorList: { Name: string; Id: number }[] = []
  maintenancePeriodList: CFMCoreEntityComboSource[] = []
  maintenanceTypeList: CFMCoreEntityComboSource[] = []
  selectedForMasterUser: { Id: number; Name: string }[] = []
  selectedForObserverUser: { Id: number; Name: string }[] = []
  //@ts-ignore
  startDate: null | Date = this.defaultStartDate ? this.defaultStartDate : null
  maintenanceCount: number = 1
  selectedTrainingPeriod: null | number = null
  workId: null | number = null

  updateGetResponse: any = {}

  userData: CFMModelSuitableWorkUserSP[] = []
  observerUserData: CFMModelSuitableWorkUserSP[] = []

  userFields = { groupBy: 'Role', text: 'UserDisplayText', value: 'Id' }
  observerUserFields = { groupBy: 'Role', text: 'ObserverUserDisplayText', value: 'Id' }

  userDataPlaceHolder = 'İşi Yapacak Personel Seçiniz'
  observerUserDataPlaceHolder = 'Refakatçi Personel Seçiniz'
  userDataForSelect = []
  //observerUserDataForSelect = [];
  observerUserDataForSelect!: { role: string; value: { ObserverUserDisplayText: string; Id: number }[] }[]
  observers: { Name: string; Id: number }[] = []

  loading = false
  async handleDelete() {
    blockui.blockModal(this)
    //@ts-ignore
    let response = (await this.apiClient.maintenancePlan.maintenancePlanDelete([this.id])).data
    if (response.IsSuccess) {
      blockui.unBlockModal()
      swal.success('Kayıt Silindi')
      this.saveSucceeded()
      this.close()
    } else {
      swal.error('Hata', 'Bir hata oluştu')
      blockui.unBlockModal()
      this.close()
    }
  }
  groupBy(list: any, keyGetter: any) {
    const map = new Map()
    list.forEach((item: any) => {
      const key = keyGetter(item)
      const collection = map.get(key)
      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })
    return map
  }
  get selectOptions() {
    return {
      searchable: true,
      placeholder: this.translate('please_select'),
      'select-label': this.translate('select_label'),
      'deselect-label': this.translate('deselect_label'),
      'selected-label': this.translate('selected_label'),
      'show-no-results': false,
    }
  }
  async created() {
    blockui.blockModal(this)
    if (this.isUpdate) {
      const res = await this.apiClient.workOrder.workOrderTrainingGetByIdWithUsers({ workOrderId: <number>this.id })
      //@ts-ignore
      this.maintenancePlan = res.data.Data
      const allUsers = this.maintenancePlan.WorkOrderUsers || []

      //@ts-ignore
      this.selectedForMasterUser = allUsers
        //@ts-ignore
        .filter((user) => user.UserPositionId === 1)
        //@ts-ignore
        .map((user) => {
          return {
            Id: user.UserId,
            Name: user.User?.NameSurname,
          }
        })

      if (this.maintenancePlan.Id) {
        this.addOrEdit = false
        this.closeOnselect = true
      }
      //@ts-ignore
      this.selectedForObserverUser = allUsers
        //@ts-ignore
        .filter((user) => user.UserPositionId === 2)
        //@ts-ignore
        .map((user) => {
          return {
            Id: user.UserId,
            Name: user.User?.NameSurname,
          }
        })
    }

    const promises = [this.getWorks(), this.getVendors(), this.getMaintenanceTypes()]

    if (this.isUpdate) {
      if (this.maintenancePlan.ScheduledTime) {
        // @ts-ignore
        this.startDate = moment(this.maintenancePlan.ScheduledTime).toDate()
      }

      // promises.push(this.getMultipleMaintenancePlanUpdate())
    }

    await Promise.all(promises)

    blockui.unBlockModal()
  }
  removeForMasterUser(args: any) {
    if (this.selectedForMasterUser.length === 1 && this.maintenancePlan.OrderStatusId !== 1) {
      toastr.error(this.translate('cannot_personnel_appointed'))
      return
    }
    this.selectedForMasterUser = this.selectedForMasterUser.filter((x: any) => x.Id !== args.Id)
  }
  removeForObserverUser(args: any) {
    //   if (this.selectedForObserverUser.length === 1) {
    //     toastr.error(this.translate('cannot_personnel_appointed'))
    //     return
    //    }
    this.selectedForObserverUser = this.selectedForObserverUser.filter((x: any) => x.Id !== args.Id)
  }
  async getMultipleMaintenancePlanUpdate() {
    blockui.blockModal(this)
    this.loading = true
    let id = this.maintenancePlan.Id
    //@ts-ignore
    this.apiClient.maintenancePlan.getMultipleMaintenancePlanUpdate({ workOrderIds: id }).then((response) => {
      //@ts-ignore
      this.maintenanceTypeList = response.data.WorkOrderTypes
      this.updateGetResponse = response.data
      //@ts-ignore
      this.maintenancePlan = response.data.WorkOrders.find((item: any) => item.WorkOder.Id === id).WorkOder
      //@ts-ignore
      this.startDate = moment(this.maintenancePlan.ScheduledTime).toDate()
      //@ts-ignore
      this.contractList = this.updateGetResponse.WorkOrders.find((item: any) => item.WorkOder.Id === id).Contracts.map(
        // @ts-ignore
        (x) => ({
          Text: x.Name + `\n(${dateFormat.viewDateFormat(x.StartDate)} - ${dateFormat.viewDateFormat(x.EndDate)})`,
          Value: x.Id,
          StartDate: x.StartDate,
          EndDate: x.EndDate,
        })
      )
      this.vendorList = this.updateGetResponse.AssignedObserverNames.map((x: any) => ({
        Name: <string>x.Text,
      }))

      this.userData = []
      this.observerUserData = []

      //@ts-ignore
      response.data.SuitableWorkUsers.forEach((user) => {
        user.ShiftDescription = user.IsHaveShift ? 'Shift: Yes' : 'Shift: No'
        user.OverlappedDescription = user.IsHaveWorkOrder ? 'Workload Availability: No' : 'Workload Availability: Yes'

        if (user.IsAssignableToMaster) {
          user.UserTrainedDescription = user.IsTrainedForMaster ? 'Trained: Yes' : 'Trained: No'
          user.UserDisplayText = `${user.Name} - ${user.UserTrainedDescription} - ${user.ShiftDescription} - ${user.OverlappedDescription}`
          this.userData.push(user)
        }

        if (user.IsAssignableToObserver) {
          user.ObserverUserTrainedDescription = user.IsTrainedForObserver ? 'Trained: Yes' : 'Trained: No'
          user.ObserverUserDisplayText = `${user.Name} - ${user.ObserverUserTrainedDescription} - ${user.ShiftDescription} - ${user.OverlappedDescription}`
          this.observerUserData.push(user)
        }
      })
      //@ts-ignore
      this.userDataForSelect = Array.from(
        // @ts-ignore
        this.groupBy(this.userData, (user) => user.Role),
        ([role, value]) => ({ role, value })
      )
      //@ts-ignore
      this.observerUserDataForSelect = Array.from(
        // @ts-ignore
        this.groupBy(this.observerUserData, (user) => user.Role),
        ([role, value]) => ({ role, value })
      )
      //@ts-ignore
      const workOrderUsers = response.data.WorkOrders.find((item: any) => item.WorkOder.Id === id).WorkOder
        .WorkOrderUsers
      this.selectedForMasterUser = workOrderUsers
        //@ts-ignore
        .filter((user) => user.UserPositionId === 1)
        //@ts-ignore
        .map((user) => {
          const matchingUser = this.userData.find((u) => u.Id === user.UserId)
          return matchingUser
            ? {
                Id: user.UserId,
                UserDisplayText: matchingUser.UserDisplayText,
              }
            : null
          //@ts-ignore
        })
        // @ts-ignore
        .filter((x) => x !== null)

      this.selectedForObserverUser = workOrderUsers
        //@ts-ignore
        .filter((user) => user.UserPositionId === 2)
        //@ts-ignore
        .map((user) => {
          const matchingUser = this.observerUserData.find((u) => u.Id === user.UserId)
          return matchingUser
            ? {
                Id: user.UserId,
                ObserverUserDisplayText: matchingUser.ObserverUserDisplayText,
              }
            : null
          //@ts-ignore
        })
        // @ts-ignore
        .filter((x) => x !== null)
      blockui.unBlockModal()
      this.loading = false
    })
  }
  async onWorkChanged(newValue: null | { Value: number; Text: string }) {
    if (newValue) {
      const selectedWork = this.fullWorkList.find((x) => x.Id == newValue.Value)

      this.maintenancePlan.Duration =
        selectedWork && selectedWork.Duration ? selectedWork.Duration : this.maintenancePlan.Duration
      this.maintenancePlan.DeviceId = null
    } else {
      this.maintenancePlan.DeviceId = null
    }
  }

  async getWorks() {
    const response = await this.apiClient.work.workGetListForTraining({ includeInactive: this.isUpdate ?? false })
    this.fullWorkList = response.data
    this.workList = Array.isArray(response.data)
      ? response.data.map((x) => ({
          Text: x.BreadCrumb ? `${x.BreadCrumb} | ${x.Name}` : x.Name,
          Value: x.Id,
        }))
      : []
  }
  async getVendors() {
    try {
      const response = await this.apiClient.user.userGetUsers()
      //@ts-ignore
      this.vendorList = response.data.map((x) => ({
        Name: x.Text,
        Id: x.Value,
      }))
      const vendor = await this.apiClient.user.userGetUsers({ userTypeId: 1 })
      //@ts-ignore
      this.observers = vendor.data.map((x) => ({
        Name: x.Text,
        Id: x.Value,
      }))
    } catch (error) {
      console.error('Vendor verileri alınırken hata oluştu:', error)
    }
  }

  async getMaintenanceTypes() {
    const response = await this.apiClient.workOrderType.workOrderTypeGetWorkOrderTypes()
    this.maintenanceTypeList = response.data
  }

  get StartDate() {
    if (this.startDate) {
      // @ts-ignore
      return moment(this.startDate).format(this.pickerDateTimeFormat)
    }
    return null
  }
  set StartDate(val) {
    if (val) {
      // @ts-ignore
      Vue.set(this, 'startDate', moment(val, this.pickerDateTimeFormat).toDate())
      this.maintenancePlan.ScheduledTime = dateFormat.postDateTimeFormat(<Date>this.startDate)
    } else {
      Vue.set(this, 'startDate', val)
      this.maintenancePlan.ScheduledTime = dateFormat.postDateTimeFormat(<Date>this.startDate)
    }
  }

  get selectedWork() {
    // @ts-ignore
    return this.selectGet('workList', 'workId', 'Value', 'Text')
  }
  set selectedWork(newValue) {
    // @ts-ignore
    return this.selectSet('workId', 'Value', newValue)
  }

  getMaintenanceLastPeriodDate() {
    let periodId = this.selectedTrainingPeriod
    // @ts-ignore
    let date = moment(this.startDate)

    if (periodId == 6) {
      // Daily
      date.add('d', this.maintenanceCount - 1)
    } else if (periodId == 5) {
      // Weekly
      let dayOfWeekLastPeriod = this.selectedDays[this.selectedDays.length - 1]
      date.add((this.maintenanceCount - 1) * 7, 'd')
      let dayOfWeekDate = date.weekday()
      date.add(<number>dayOfWeekLastPeriod - dayOfWeekDate, 'd')
    } else if (periodId == 1) {
      // Monthly
      date.add(this.maintenanceCount - 1, 'M')
      let monthLastDay = date.daysInMonth()
      let nowDay = date.date()
      //@ts-ignore
      let dayOfMonthLastPeriod =
        this.selectedMonthlyDays.length > 0
          ? this.selectedMonthlyDays.sort(function (a, b) {
              return a - b
              // @ts-ignore
            })[this.selectedMonthlyDays.length - 1].value
          : 0

      if (monthLastDay < dayOfMonthLastPeriod) {
        date.add(monthLastDay - nowDay, 'd')
      } else if (monthLastDay >= dayOfMonthLastPeriod) {
        date.add(dayOfMonthLastPeriod - nowDay, 'd')
      }
    }

    return date.toDate()
  }

  async onSubmit() {
    try {
      // Check selectedMonthlyDays
      if (!Array.isArray(this.selectedMonthlyDays)) {
        console.error('selectedMonthlyDays bir array değil:', this.selectedMonthlyDays)
        throw new Error(this.translate('training_error'))
      }

      // Check selectedForMasterUser
      if (this.selectedForMasterUser.length === 0) {
        throw new Error(this.translate('training_error'))
      }

      if (!this.selectedTrainingPeriod) {
        throw new Error(this.translate('training_error'))
      }

      if (!this.startDate) {
        throw new Error(this.translate('training_error'))
      }

      if (!this.maintenancePlan.Duration) {
        throw new Error(this.translate('training_error'))
      }

      // Form verileri hazırlama
      const DaysOfMonth = Array.isArray(this.selectedMonthlyDays)
        ? this.selectedMonthlyDays.map((day: any) => day.Id)
        : []

      const AssignedIds = Array.isArray(this.selectedForMasterUser)
        ? this.selectedForMasterUser.map((item: { Id: number }) => item.Id)
        : typeof this.selectedForMasterUser === 'object'
        ? [(this.selectedForMasterUser as { Id: number }).Id]
        : []

      const ObserverIds = Array.isArray(this.selectedForObserverUser)
        ? this.selectedForObserverUser.map((item: { Id: number }) => item.Id)
        : []

        if (!AssignedIds || !Array.isArray(AssignedIds) || AssignedIds.length === 0) {
          throw new Error(this.translate('training_vendor_enter'));
        }

      const data = {
        Id: this.maintenancePlan.Id,
        WorkId: this.workId || this.maintenancePlan.WorkId,
        PeriodId: this.selectedTrainingPeriod,
        TrainingCount: this.maintenanceCount,
        OrderSourceId: 4,
        OrderStatusId: 2,
        OrderTypeId: 1,
        Note: this.maintenancePlan.Note,
        ScheduledTime: dateFormat.postDateTimeFormat(this.startDate),
        Duration: this.maintenancePlan.Duration,
        DaysOfWeek: this.selectedDays,
        DaysOfMonth,
        RepeatedDays: this.repeatedDays,
        AssignedIds,
        ObserverIds,
      }

      const formData = {
        AssignedIds: data.AssignedIds,
        ObserverIds: data.ObserverIds,
        WorkOrderTraining: data,
      }

      blockui.blockModal(this)
      // API çağrısı
      //@ts-ignore
      const response = await this.apiClient.workOrder.trainingAsignmentSave(formData)

      if (response.data.IsSuccess) {
        toastr.success(this.translate('save_successfully'))
        this.saveSucceeded()
        this.close()
      } else {
        const errorMessage = response.data.Message || 'Error'
        toastr.error(errorMessage)
      }
    } catch (error) {
      console.error('onSubmit sırasında hata:', error)
      toastr.error(String(error))
    } finally {
      blockui.unBlockModal()
    }
  }
}
