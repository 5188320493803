import Vue from 'vue'
import VueResource from 'vue-resource'
import { getCookieByName } from '../global/util'

Vue.use(VueResource)
export const baseUrl = process.env.VUE_APP_API_BASE_URL

//Vue.http.options.emulateJSON = true;

Vue.http.interceptors.push((request, next) => {
  request.headers.set('Accept', 'application/json')
  request.headers.set('Content-Type', 'application/json')
  request.headers.set('Culture', localStorage.getItem('vue-lang'))
  if (request.url.endsWith('/Access/Token')) {
  } else {
    // request.headers.set('Content-Type', 'text/plain');
    request.headers.set('Authorization', `${getCookieByName('tokenType')} ${getCookieByName('token')}`)
  }
  next((res) => {
    if (!res.ok) {
      switch (res.status) {
        case 400:
          if (request.url.endsWith('/Access/Token')) {
            toastr.error(res.body.error_description)
          } else {
            toastr.error(res.body ? res.body : 'ERROR!')
          }
          break
        case 401:
          // toastr.error("401 Unauthorized", res.body);
          toastr.error(res.body)
          /* if (request.method === "POST") {
                         window.location = "#/401";
                     } */
          break
        case 403:
          toastr.error('403 Forbidden', res.body)
          break
        case 404:
          toastr.error('404 Not Found', res.body)
          break
        case 405:
          toastr.error('405 Method Not Allowed', res.body)
          break
        case 408:
          toastr.error('408 Request Timeout', res.body)
          break
        default:
          toastr.error('Error', res.body)
      }
      throw res.body
    }
  })
})

// #region Calendar List Methods
export const getShiftCalendarList = (data) => Vue.http.get(`${baseUrl}/Shift/Search`, { params: data })
export const GetByDateRange = (data) => Vue.http.get(`${baseUrl}/Shift/GetByDateRange`, { params: data })
export const getStaffShiftCountByDateRange = (data) =>
  Vue.http.get(`${baseUrl}/Shift/GetStaffCountInShift`, { params: data })
export const GetReservationsByDateRange = (data) =>
  Vue.http.get(`${baseUrl}/Reservation/GetByDateRange`, { params: data })

export const getMaintenancePlanCalendarList = (data) =>
  Vue.http.get(`${baseUrl}/MaintenancePlan/SearchMonthly`, { params: data })
// #endregion Calendar List Methods

// #region Datatable List Methods
export const getContinentList = () => Vue.http.get(`${baseUrl}/Continent/GetList`)
export const getQAQCList = () => Vue.http.get(`${baseUrl}/QAQC/GetList`)
export const getQAQCListByWorkOrderId = (workOrderId) =>
  Vue.http.get(`${baseUrl}/QAQCWorkOrder/GetByWorkOrderId`, { params: { workOrderId } })
export const getCountryList = (data) => Vue.http.get(`${baseUrl}/Country/GetList`, { params: data })
export const getCityList = (data) => Vue.http.get(`${baseUrl}/City/Search`, { params: data })
export const getWorkList = (data) => Vue.http.get(`${baseUrl}/Work/GetList`, { params: data })
export const getWorkListWithBreadCrumb = (data) =>
  Vue.http.get(`${baseUrl}/Work/GetListWithBreadCrumb`, { params: data })
export const getWorkSearch = (data) => Vue.http.get(`${baseUrl}/Work/Search`, { params: data })
export const getDistrictList = (data) => Vue.http.get(`${baseUrl}/District/Search`, { params: data })
export const getBuildingList = (data) => Vue.http.get(`${baseUrl}/Building/Search`, { params: data })
export const getRoomList = (data) => Vue.http.get(`${baseUrl}/Room/Search`, { params: data })
export const getCampusList = (data) => Vue.http.get(`${baseUrl}/Campus/Search`, { params: data })
export const getRoomTypeList = () => Vue.http.get(`${baseUrl}/RoomType/GetList`)
export const getRoomSubTypeList = (data) => Vue.http.get(`${baseUrl}/RoomSubType/GetList`, { params: data })
export const getInventoryList = () => Vue.http.get(`${baseUrl}/Device/GetList`)
export const getCapacityTypeList = (data) => Vue.http.get(`${baseUrl}/CapacityType/Search`, { params: data })
export const getProductFamilyList = () => Vue.http.get(`${baseUrl}/ProductFamily/GetList`)
export const getAllDevices = (data) => Vue.http.get(`${baseUrl}/Device/GetAllDevices`, { params: data })
export const getAllDeviceSearch = (data) => Vue.http.get(`${baseUrl}/Device/SearchInventory`, { params: data })
export const getDeviceListByContractId = (ContractId) =>
  Vue.http.get(`${baseUrl}/Device/GetDevicesByContractId?contractId=${ContractId}`)
export const getVendorList = () => Vue.http.get(`${baseUrl}/Vendor/GetList`)
export const getCapacityEquipmentList = (data) => Vue.http.get(`${baseUrl}/CapacityEquipment/GetList`, { params: data })
export const getCapacityEquipmentSearch = (data) =>
  Vue.http.get(`${baseUrl}/CapacityEquipment/Search`, { params: data })
export const getDeviceList = (data) => Vue.http.get(`${baseUrl}/Device/GetList`, { params: data })
export const getUserRoleList = (data) => Vue.http.get(`${baseUrl}/Role/GetList`, { params: data })
export const getTrainingTypeList = () => Vue.http.get(`${baseUrl}/TrainingType/GetList`)
export const getTrainingList = (data) => Vue.http.get(`${baseUrl}/Training/GetList`, { params: data })
export const getTrainingListCard = (data) =>
  Vue.http.get(`${baseUrl}/Training/GetWorkOrderTraining?workOrderId=${data}`)
export const getAssessmentList = (data) => Vue.http.get(`${baseUrl}/Assessment/GetList`, { params: data })
export const getTrainingMethodList = (data) => Vue.http.get(`${baseUrl}/TrainingMethod/GetList`, { params: data })
export const getUserTypeList = () => Vue.http.get(`${baseUrl}/UserType/GetList`)
export const getUserList = (data) => Vue.http.get(`${baseUrl}/User/Search`, { params: data })
export const getWorkOrderTypeList = () => Vue.http.get(`${baseUrl}/WorkOrderType/GetList`)
export const getWorkTypeList = () => Vue.http.get(`${baseUrl}/WorkOrderType/GetList`)
export const getWorkCategoryList = () => Vue.http.get(`${baseUrl}/WorkCategory/GetList`)
export const getWorkOrderCategories = () => Vue.http.get(`${baseUrl}/Common/GetWorkOrderCategories`)
export const getJobList = () => Vue.http.get(`${baseUrl}/Job/GetList`)
export const getQualificationTypeList = () => Vue.http.get(`${baseUrl}/QualificationType/GetList`)
export const getQualificationList = (data) => Vue.http.get(`${baseUrl}/Qualification/GetList`, { params: data })
export const getInfrastructureList = () => Vue.http.get(`${baseUrl}/Infrastructure/GetList`)
export const getInfrastructureGroupList = (data) =>
  Vue.http.get(`${baseUrl}/InfrastructureGroup/GetList`, { params: data })
export const getInfrastructureTypeList = (data) =>
  Vue.http.get(`${baseUrl}/InfrastructureType/GetList`, { params: data })
export const getShiftTypeList = () => Vue.http.get(`${baseUrl}/ShiftType/GetList`)
export const getPriorityTypeList = () => Vue.http.get(`${baseUrl}/PriorityType/GetAll`)
export const getMaintenancePlanList = (data) => Vue.http.get(`${baseUrl}/MaintenancePlan/Search`, { params: data })
export const getWorkOrderList = (data) => Vue.http.get(`${baseUrl}/WorkOrder/Search`, { params: data })
export const getWorkOrderListCal = (data) => Vue.http.get(`${baseUrl}/WorkOrder/SearchDashboard`, { params: data })
export const getIncidentList = (data) => Vue.http.get(`${baseUrl}/Incident/Search`, { params: data })
export const searchWorkOrderName = (name, orderSourceId) =>
  Vue.http.get(`${baseUrl}/WorkOrder/SearchWorkOrderName`, { params: { name, orderSourceId } })
export const getWorkOrderCountByStatus = (data) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetCountByStatus`, { params: data })
export const getPendingMaintenancePlanForAssignList = (data) =>
  Vue.http.get(`${baseUrl}/MaintenancePlan/GetPendingForAssignList`, { params: data })
export const getMaintenancePeriodList = () => Vue.http.get(`${baseUrl}/WorkPeriod/GetList`)
export const getUserPositionList = () => Vue.http.get(`${baseUrl}/UserPosition/GetList`)
export const getScheduleList = () => Vue.http.get(`${baseUrl}/Schedule/GetList`)
export const getProcedureList = (data) => Vue.http.get(`${baseUrl}/Procedure/GetList`, { params: data })
export const getTrainingMaterialList = () => Vue.http.get(`${baseUrl}/TrainingMaterial/GetList`)
export const getDeviceConstraintList = () => Vue.http.get(`${baseUrl}/ScheduleDevice/GetList`)
export const getWorkConstraintList = () => Vue.http.get(`${baseUrl}/ScheduleWork/GetList`)
export const getRoomConstraintList = () => Vue.http.get(`${baseUrl}/ScheduleRoom/GetList`)
export const getTrainingAssessmentList = () => Vue.http.get(`${baseUrl}/Assessment/GetList`)
export const getTrainingAssessmentTypeList = () => Vue.http.get(`${baseUrl}/AssessmentType/GetList`)
export const getWorkGroupList = () => Vue.http.get(`${baseUrl}/WorkGroup/GetList`)
export const getWorkOrderStatusList = (workOrderSourceId) =>
  Vue.http.get(`${baseUrl}/WorkOrderStatus/GetList`, { params: { workOrderSourceId } })
export const getWorkOrderSubStatusList = (data) =>
  Vue.http.get(`${baseUrl}/WorkOrderSubStatus/GetList`, { params: data })
export const searchAssignedWorkOrder = (data) =>
  Vue.http.get(`${baseUrl}/WorkOrder/SearchAssignedWorkOrder`, { params: data })
export const getSecurityItemList = (data) => Vue.http.get(`${baseUrl}/SecurityItem/GetList`, { params: data })
export const getActorList = () => Vue.http.get(`${baseUrl}/SecurityActor/GetList`)
export const getActionGroupList = () => Vue.http.get(`${baseUrl}/SecurityActionGroup/GetList`)
export const getActionActorsList = () => Vue.http.get(`${baseUrl}/SecurityActionActor/GetList`)
export const getRoleGroupList = (data) => {
  data = { actorId: data }
  return Vue.http.get(`${baseUrl}/RoleGroup/GetList`, { params: data })
}
export const getActorRolesAndUsers = (data) =>
  Vue.http.get(`${baseUrl}/SecurityActor/GetActorRolesandUsers`, { params: data })
export const getUnitList = (data) => Vue.http.get(`${baseUrl}/Unit/GetList`, { params: data })
export const getUnitTypes = (data) => Vue.http.get(`${baseUrl}/UnitType/GetUnitTypes`, { params: data })
export const getUnitTypeList = (data) => Vue.http.get(`${baseUrl}/UnitType/GetList`, { params: data })
export const getReferenceLibraryList = (data) => Vue.http.get(`${baseUrl}/ReferenceLibrary/GetList`, { params: data })
export const getServiceProviderList = (data) => Vue.http.get(`${baseUrl}/ServiceProvider/GetList`, { params: data })
export const getDescriptionList = (qs) => {
  qs = { deviceId: qs.deviceId }
  return Vue.http.get(`${baseUrl}/Description/GetList`, { params: qs })
}
export const getFinancialItemList = (data) => Vue.http.get(`${baseUrl}/Financial/GetList`, { params: data })
export const getMassMaintenancePlanList = (data) =>
  Vue.http.get(`${baseUrl}/MaintenanceGroup/GetList`, { params: data })
export const getPendingApprovalList = (data) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetPendingApprovals`, { params: data })
export const getWorkOrderStatusDescriptionList = (data) =>
  Vue.http.get(`${baseUrl}/WorkOrderStatusDescription/GetList`, { params: data })
export const getWorkOrderListByMaintenanceGroupId = (data) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetViewListByMaintenanceGroupId`, { params: data })
export const getFormList = (data) => Vue.http.get(`${baseUrl}/Form/GetList`, { params: data })
export const getFormListForFilter = () => Vue.http.get(`${baseUrl}/Form/GetFormListForFilter`)
export const getListByWorkForm = (data) => Vue.http.get(`${baseUrl}/Form/GetListByWorkForm`, { params: data })
export const getAvailableFormList = (workId) =>
  Vue.http.get(`${baseUrl}/Form/GetAvailableFormList`, { params: { workId } })
export const getAvailableSOPFormListForDeviceOrRoom = (deviceId, roomId) =>
  Vue.http.get(`${baseUrl}/Form/GetAvailableSOPFormListForDeviceOrRoom`, { params: { deviceId, roomId } })
export const getAvailableSOPFormListForWorkOrder = (workOrderId) =>
  Vue.http.get(`${baseUrl}/Form/GetAvailableSOPFormListForWorkOrder`, { params: { workOrderId } })
export const deleteWorkOrderForm = (workOrderId, baseFormId) =>
  Vue.http.get(`${baseUrl}/WorkOrder/DeleteForm`, { params: { workOrderId, baseFormId } })
export const addWorkOrderForm = (workOrderId, baseFormId) =>
  Vue.http.get(`${baseUrl}/WorkOrder/AddForm`, { params: { workOrderId, baseFormId } })
export const getQuestionList = (data) => Vue.http.get(`${baseUrl}/Question/GetList`, { params: data })
export const getQuestionGroupList = (data) => Vue.http.get(`${baseUrl}/QuestionGroup/GetList`, { params: data })
export const getFormProcessList = (data) => Vue.http.get(`${baseUrl}/FormProcess/GetList`, { params: data })
export const getFormSectionList = (data) => Vue.http.get(`${baseUrl}/FormSection/GetList`, { params: data })
export const searchFormProcess = (data) => Vue.http.get(`${baseUrl}/FormProcess/Search`, { params: data })
export const getUserTrainingList = (data) => Vue.http.get(`${baseUrl}/UserTraining/GetList`, { params: data })
export const getUserOvertimeList = (data) => Vue.http.get(`${baseUrl}/UserOvertime/GetByUserId`, { params: data })
export const getUserExcuseList = (data) => Vue.http.get(`${baseUrl}/UserExcuse/GetByUserId`, { params: data })
export const getExcuseTypeList = () => Vue.http.get(`${baseUrl}/ExcuseType/GetList`)
export const getUserAssessmentList = (data) => Vue.http.get(`${baseUrl}/UserAssessment/GetList`, { params: data })
export const getToolTypeList = (data) => Vue.http.get(`${baseUrl}/ToolType/GetList`, { params: data })
export const getToolSubTypeList = (data) => Vue.http.get(`${baseUrl}/ToolSubType/GetList`, { params: data })
export const getWarehouseList = (data) => Vue.http.get(`${baseUrl}/Warehouse/GetList`, { params: data })
export const getPointList = (data) => Vue.http.get(`${baseUrl}/Point/GetList`, { params: data })
export const getPointDefinitionList = (data) => Vue.http.get(`${baseUrl}/Definition/GetList`, { params: data })
export const getFormQuestionTypeComparisonOperatorList = () =>
  Vue.http.get(`${baseUrl}/FormQuestionTypeComparisonOperator/GetList`)
export const getComparisonOperatorList = () => Vue.http.get(`${baseUrl}/ComparisonOperator/GetList`)
export const getOperandTypeList = () => Vue.http.get(`${baseUrl}/OperandType/GetList`)
export const getFormProcessFiles = (workOrderId, formId) =>
  Vue.http.get(`${baseUrl}/FormProcess/GetFormProcessFiles`, { params: { workOrderId, formId } })
export const getFormStatusList = () => Vue.http.get(`${baseUrl}/FormStatus/GetList`)
export const getSecurityActionGroupList = () => Vue.http.get(`${baseUrl}/SecurityActionGroup/GetList`)
export const getSecurityActionList = () => Vue.http.get(`${baseUrl}/SecurityAction/GetList`)
export const getContractSectionList = (contractId) =>
  Vue.http.get(`${baseUrl}/ContractSection/GetList`, { params: { contractId } })
export const getBrandList = (data) => Vue.http.get(`${baseUrl}/Brand/GetList`, { params: data })
export const getToolBrandList = (data) => Vue.http.get(`${baseUrl}/ToolBrand/GetList`, { params: data })
export const getModelList = (data) => Vue.http.get(`${baseUrl}/Model/GetList`, { params: data })
export const getCapacityEquipmentModelList = (data) =>
  Vue.http.get(`${baseUrl}/CapacityEquipmentModel/GetList`, { params: data })
export const getToolModelList = (data) => Vue.http.get(`${baseUrl}/ToolModel/GetList`, { params: data })
export const getContractList = (data) => Vue.http.get(`${baseUrl}/Contract/GetList`, { params: data })
export const getContractViewList = (data) => Vue.http.get(`${baseUrl}/Contract/GetContractViewList`, { params: data })
export const getContractSlaList = (SectionId) =>
  Vue.http.get(`${baseUrl}/ContractSla/Getlist`, { params: { SectionId } })
// #endregion Datatable List Methods

// #region Dropdown List Methods
export const getAllRooms = () => Vue.http.get(`${baseUrl}/Room/GetAllRooms`)
export const getAllBuildings = () => Vue.http.get(`${baseUrl}/Location/GetAllBuildings`)
export const getAllCampuses = () => Vue.http.get(`${baseUrl}/Location/GetAllCampuses`)
export const getContinents = () => Vue.http.get(`${baseUrl}/Location/GetContinents`)
export const getCountries = (qs) => Vue.http.get(`${baseUrl}/Location/GetCountries?continentId=${qs.continentId}`)
export const getCities = (qs) => Vue.http.get(`${baseUrl}/Location/GetCities?countryId=${qs.countryId}`)
export const getDistricts = (qs) => Vue.http.get(`${baseUrl}/Location/GetDistricts?cityId=${qs.cityId}`)
export const getCampuses = (qs) => Vue.http.get(`${baseUrl}/Location/GetCampuses?districtId=${qs.districtId}`)
export const getBuildings = (qs) => Vue.http.get(`${baseUrl}/Location/GetBuildings?campusId=${qs.campusId}`)
export const getFloors = (qs) => Vue.http.get(`${baseUrl}/Location/GetFloors?buildingId=${qs.buildingId}`)
export const getRooms = (data) => Vue.http.post(`${baseUrl}/Location/GetRooms`, { params: data })
export const getRoomTypes = () => Vue.http.get(`${baseUrl}/Location/GetRoomTypes`)
export const getRoomSubTypes = (qs) => Vue.http.get(`${baseUrl}/Location/GetRoomSubTypes?roomTypeId=${qs.roomTypeId}`)
export const getCapacityTypes = (data) => Vue.http.get(`${baseUrl}/CapacityType/GetCapacityTypes`, { params: data })
export const getCapacityEquipments = (data) =>
  Vue.http.get(`${baseUrl}/CapacityEquipment/GetCapacityEquipments`, { params: data })
export const getCapacityEquipmentsBreadCrumb = (data) =>
  Vue.http.get(`${baseUrl}/CapacityEquipment/GetCapacityEquipmentsBreadCrumb`, { params: data })
export const getDevicesBreadCrumb = (data) => Vue.http.get(`${baseUrl}/Device/GetDevicesBreadCrumb`, { params: data })
export const getProductFamilies = () => Vue.http.get(`${baseUrl}/ProductFamily/GetProductFamilies`)
export const getVendors = () => Vue.http.get(`${baseUrl}/Vendor/GetVendors`)
export const getTrainingTypes = () => Vue.http.get(`${baseUrl}/TrainingType/GetTrainingTypes`)
export const getTrainingMethods = () => Vue.http.get(`${baseUrl}/TrainingMethod/GetTrainingMethods`)
export const getTrainings = () => Vue.http.get(`${baseUrl}/Training/GetTrainings`)
export const getUserRoles = () => Vue.http.get(`${baseUrl}/Role/GetRoles`)
export const getUserTypes = () => Vue.http.get(`${baseUrl}/UserType/GetUserTypes`)
export const getUserPositions = () => Vue.http.get(`${baseUrl}/UserPosition/GetUserPositions`)
export const getOrganizations = (data) => Vue.http.get(`${baseUrl}/Organization/GetOrganizations`, { params: data })
export const getUsers = (data) => Vue.http.get(`${baseUrl}/User/GetUsers`, { params: data })
export const getUserListWithoutIncludes = (data) => Vue.http.get(`${baseUrl}/User/GetUserListWithoutIncludes`)
export const getUsersForCalendar = (data) => Vue.http.get(`${baseUrl}/User/getUsersForCalendar`, { params: data })
export const getWorks = (data) => Vue.http.get(`${baseUrl}/Work/GetWorks`, { params: data })
export const getWorkOrderTypes = () => Vue.http.get(`${baseUrl}/WorkOrderType/GetWorkOrderTypes`)
export const getWorkCategories = () => Vue.http.get(`${baseUrl}/WorkCategory/GetWorkCategories`)
export const getJobs = () => Vue.http.get(`${baseUrl}/Job/GetAllJobs`)
export const getQualificationTypes = () => Vue.http.get(`${baseUrl}/QualificationType/GetQualificationTypes`)
export const getQualifications = (data) => Vue.http.get(`${baseUrl}/Qualification/GetQualifications`, { params: data })
export const getInfrastructures = () => Vue.http.get(`${baseUrl}/Infrastructure/GetInfrastructures`)
export const getInfrastructureTypes = (qs) =>
  Vue.http.get(`${baseUrl}/InfrastructureType/GetInfrastructureTypes?infrastructureGroupId=${qs.infrastructureGroupId}`)
export const getInfrastructureGroups = (qs) =>
  Vue.http.get(`${baseUrl}/InfrastructureGroup/GetInfrastructureGroups?infrastructureId=${qs.infrastructureId}`)
export const getShiftTypes = () => Vue.http.get(`${baseUrl}/ShiftType/GetShiftTypes`)
export const getWorkOrderStatuses = (workOrderSourceId) =>
  Vue.http.get(`${baseUrl}/WorkOrderStatus/GetWorkOrderStatuses`, { params: { workOrderSourceId } })
export const getWorkOrderSubStatuses = (data) =>
  Vue.http.get(`${baseUrl}/WorkOrderSubStatus/GetWorkOrderSubStatuses`, { params: data })
export const getMaintenancePeriods = () => Vue.http.get(`${baseUrl}/WorkPeriod/GetPeriods`)
export const getUnits = (data) => Vue.http.get(`${baseUrl}/Unit/GetUnits`, { params: data })
export const getWorkOrderUserListWithUser = (qs) =>
  Vue.http.get(`${baseUrl}/WorkOrderUser/GetListWithUser?workOrderId=${qs.workOrderId}`)
export const getWorkOrderUserList = (workOrderId, isIncludeWorkOrders, isIncludeUser) =>
  Vue.http.post(`${baseUrl}/WorkOrderUser/GetList`, { workOrderId, isIncludeWorkOrders, isIncludeUser })
export const getSuitableWorkUsers = (data) =>
  Vue.http.get(`${baseUrl}/WorkOrderUser/GetSuitableWorkUsers`, { params: data })
export const getProcedureTypes = () => Vue.http.get(`${baseUrl}/ProcedureType/GetProcedureTypes`)
export const getProcedures = () => Vue.http.get(`${baseUrl}/Procedure/GetProcedures`)
export const getTrainingMaterials = () => Vue.http.get(`${baseUrl}/Material/GetMaterials`)
export const getTrainingAssessments = () => Vue.http.get(`${baseUrl}/Assessment/GetAssessments`)
export const getTrainingAssessmentTypes = (data) =>
  Vue.http.get(`${baseUrl}/AssessmentType/GetAssessmentTypes`, { params: data })
export const getWorkGroups = () => Vue.http.get(`${baseUrl}/WorkGroup/GetWorkGroups`)
export const getSecurityItemTypes = () => Vue.http.get(`${baseUrl}/Common/GetSecurityItemTypes`)
export const getActors = () => Vue.http.get(`${baseUrl}/Actor/GetActors`)
export const getUserExperiences = () => Vue.http.get(`${baseUrl}/UserExperience/GetUserExperiences`)
export const getRoomCodes = () => Vue.http.get(`${baseUrl}/Location/GetRoomCodes`)
export const getDocumentStatuses = () => Vue.http.get(`${baseUrl}/DocumentStatus/GetDocumentStatuses`)
export const getDocumentValidityStatuses = () =>
  Vue.http.get(`${baseUrl}/DocumentValidityStatus/GetDocumentValidityStatuses`)
export const getReferenceLibraries = (data) =>
  Vue.http.get(`${baseUrl}/ReferenceLibrary/GetReferenceLibraries`, { params: data })
export const getWorksWithBreadCrumb = () => Vue.http.get(`${baseUrl}/Work/GetWorksWithBreadCrumb`)
export const getMaintenanceGroups = () => Vue.http.get(`${baseUrl}/MaintenanceGroup/GetMaintenanceGroups`)
export const getWorkOrderStatusDescriptions = (data) =>
  Vue.http.get(`${baseUrl}/WorkOrderStatusDescription/GetWorkOrderStatusDescriptions`, { params: data })
export const getFormStatuses = () => Vue.http.get(`${baseUrl}/FormStatus/GetFormStatuses`)
export const getFormProcessStatuses = () => Vue.http.get(`${baseUrl}/FormProcessStatus/GetFormProcessStatuses`)
export const getFormQuestionTypes = () => Vue.http.get(`${baseUrl}/FormQuestionType/GetFormQuestionTypes`)
export const getSchedules = () => Vue.http.get(`${baseUrl}/Schedule/GetSchedules`)
export const getFormSections = (data) => Vue.http.get(`${baseUrl}/FormSection/GetFormSections`, { params: data })
export const getToolTypes = () => Vue.http.get(`${baseUrl}/ToolType/GetToolTypes`)
export const getToolSubTypes = (data) => Vue.http.get(`${baseUrl}/ToolSubType/GetToolSubTypes`, { params: data })
export const getToolStatuses = (data) => Vue.http.get(`${baseUrl}/ToolStatus/GetToolStatuses`, { params: data })
export const getPointDefinitions = () => Vue.http.get(`${baseUrl}/Definition/GetDefinitions`)
export const getPointTypes = () => Vue.http.get(`${baseUrl}/PointType/GetPointTypes`)
export const getDayOfMonths = () => Vue.http.get(`${baseUrl}/Common/GetDayOfMonths`)
export const getDayOfWeeks = () => Vue.http.get(`${baseUrl}/Common/GetDayOfWeeks`)
export const getMonths = () => Vue.http.get(`${baseUrl}/Common/GetMonths`)
export const getWeeks = () => Vue.http.get(`${baseUrl}/Common/GetWeeks`)
export const getBrands = () => Vue.http.get(`${baseUrl}/Brand/GetBrands`)
export const getToolBrands = () => Vue.http.get(`${baseUrl}/ToolBrand/GetToolBrands`)
export const getCapacityEquipmentBrands = () => Vue.http.get(`${baseUrl}/CapacityEquipmentBrand/GetBrands`)
export const getModels = (data) => Vue.http.get(`${baseUrl}/Model/GetModels`, { params: data })
export const getToolModels = (data) => Vue.http.get(`${baseUrl}/ToolModel/GetToolModels`, { params: data })
export const getCapacityEquipmentModels = (data) =>
  Vue.http.get(`${baseUrl}/CapacityEquipmentModel/GetModels`, { params: data })
export const getContractFileType = () => Vue.http.get(`${baseUrl}/Common/GetContractFileTypes`)
// #endregion Dropdown List Methods

// #region Modal Get Methods
export const getUser = (id) => Vue.http.get(`${baseUrl}/User/GetUser?Id=${id}`)
export const getVendorUser = (id) => Vue.http.get(`${baseUrl}/User/GetVendorUsers?vendorId=${id}`)
export const getCapacityEquipment = (id) => Vue.http.get(`${baseUrl}/CapacityEquipment/GetCapacityEquipment?Id=${id}`)
export const getContinent = (id) => Vue.http.get(`${baseUrl}/Continent/GetById?Id=${id}`)
export const getCountry = (id) => Vue.http.get(`${baseUrl}/Country/GetById?Id=${id}`)
export const getCity = (id) => Vue.http.get(`${baseUrl}/City/GetById?Id=${id}`)
export const getDistrict = (id) => Vue.http.get(`${baseUrl}/District/GetById?Id=${id}`)
export const getCampus = (id) => Vue.http.get(`${baseUrl}/Campus/GetById?Id=${id}`)
export const getTraining = (id) => Vue.http.get(`${baseUrl}/Training/GetById?Id=${id}`)
export const getWork = (id) => Vue.http.get(`${baseUrl}/Work/GetById?Id=${id}`)
export const getWorkWithBreadCrumb = (id) => Vue.http.get(`${baseUrl}/Work/GetWork?Id=${id}`)
export const getWorkOrder = (id) => Vue.http.get(`${baseUrl}/WorkOrder/GetById?Id=${id}`)
export const getMaintenancePlan = (id) => Vue.http.get(`${baseUrl}/MaintenancePlan/GetById?Id=${id}`)
export const getSchedule = (id) => Vue.http.get(`${baseUrl}/Schedule/GetById?Id=${id}`)
export const getMaintenancePeriod = (id) => Vue.http.get(`${baseUrl}/WorkPeriod/GetById?Id=${id}`)
export const getRoomType = (id) => Vue.http.get(`${baseUrl}/RoomType/GetById?Id=${id}`)
export const getRoomSubType = (id) => Vue.http.get(`${baseUrl}/RoomSubType/GetById?Id=${id}`)
export const getVendor = (id) => Vue.http.get(`${baseUrl}/Vendor/GetById?Id=${id}`)
export const getWorkOrderType = (id) => Vue.http.get(`${baseUrl}/WorkOrderType/GetById?Id=${id}`)
export const getTrainingType = (id) => Vue.http.get(`${baseUrl}/TrainingType/GetById?Id=${id}`)
export const getShiftType = (id) => Vue.http.get(`${baseUrl}/ShiftType/GetById?Id=${id}`)
export const getUserRole = (id) => Vue.http.get(`${baseUrl}/Role/GetById?Id=${id}`)
export const getQualification = (id) => Vue.http.get(`${baseUrl}/Qualification/GetById?Id=${id}`)
export const getProductFamily = (id) => Vue.http.get(`${baseUrl}/ProductFamily/GetById?Id=${id}`)
export const getRoom = (id) => Vue.http.get(`${baseUrl}/Room/GetById?Id=${id}`)
export const getBuilding = (id) => Vue.http.get(`${baseUrl}/Building/GetBuildingView?Id=${id}`)
export const getInfrastructureType = (id) =>
  Vue.http.get(`${baseUrl}/InfrastructureType/GetInfrastructureTypeView?Id=${id}`)
export const getInfrastructureGroup = (id) => Vue.http.get(`${baseUrl}/InfrastructureGroup/GetById?Id=${id}`)
export const getQualificationType = (id) => Vue.http.get(`${baseUrl}/QualificationType/GetById?Id=${id}`)
export const getInfrastructure = (id) => Vue.http.get(`${baseUrl}/Infrastructure/GetById?Id=${id}`)
export const getJob = (id) => Vue.http.get(`${baseUrl}/Job/GetById?Id=${id}`)
export const getCapacityType = (id) => Vue.http.get(`${baseUrl}/CapacityType/GetById?Id=${id}`)
export const getDevice = (id) => Vue.http.get(`${baseUrl}/Device/GetById?Id=${id}`)
export const getProcedure = (id) => Vue.http.get(`${baseUrl}/Procedure/GetById?Id=${id}`)
export const getTrainingMaterial = (id) => Vue.http.get(`${baseUrl}/TrainingMaterial/GetById?Id=${id}`)
export const getDeviceConstraint = (id) => Vue.http.get(`${baseUrl}/ScheduleDevice/GetById?Id=${id}`)
export const getWorkConstraint = (id) => Vue.http.get(`${baseUrl}/ScheduleWork/GetById?Id=${id}`)
export const getRoomConstraint = (id) => Vue.http.get(`${baseUrl}/ScheduleRoom/GetById?Id=${id}`)
export const getTrainingAssessment = (id) => Vue.http.get(`${baseUrl}/Assessment/GetById?Id=${id}`)
export const getTrainingAssessmentType = (id) => Vue.http.get(`${baseUrl}/AssessmentType/GetById?Id=${id}`)
export const getUserTraining = (id) => Vue.http.get(`${baseUrl}/UserTraining/GetById?Id=${id}`)
export const getUserAssessment = (id) => Vue.http.get(`${baseUrl}/UserAssessment/GetById?Id=${id}`)
export const getWorkGroup = (id) => Vue.http.get(`${baseUrl}/WorkGroup/GetById?Id=${id}`)
export const getSecurityItem = (id) => Vue.http.get(`${baseUrl}/SecurityItem/GetById?Id=${id}`)
export const getReferenceLibraryItem = (id) => Vue.http.get(`${baseUrl}/ReferenceLibrary/GetById?Id=${id}`)
export const getServiceProviderItem = (id) => Vue.http.get(`${baseUrl}/ServiceProvider/GetById?Id=${id}`)
export const getDescriptionItem = (id) => Vue.http.get(`${baseUrl}/Description/GetById?Id=${id}`)
export const getFinancialItem = (id) => Vue.http.get(`${baseUrl}/Financial/GetById?Id=${id}`)
export const getMassMaintenancePlan = (id) => Vue.http.get(`${baseUrl}/MaintenanceGroup/GetById?Id=${id}`)
export const getWorkOrderStatusDescription = (id) =>
  Vue.http.get(`${baseUrl}/WorkOrderStatusDescription/GetById?Id=${id}`)
export const getForm = (id) => Vue.http.get(`${baseUrl}/Form/GetById?Id=${id}`)
export const getQuestion = (id) => Vue.http.get(`${baseUrl}/Question/GetById?Id=${id}`)
export const getQuestionGroup = (id) => Vue.http.get(`${baseUrl}/QuestionGroup/GetById?Id=${id}`)
export const getWorkOrderForm = (workOrderId, formId) =>
  Vue.http.get(`${baseUrl}/Form/GetWorkOrderForm?workOrderId=${workOrderId}&formId=${formId}`)
export const getFormWithProcess = (data) => Vue.http.get(`${baseUrl}/Form/GetFormWithProcess`, { params: data })
export const getToolType = (id) => Vue.http.get(`${baseUrl}/ToolType/GetById?Id=${id}`)
export const getToolSubType = (id) => Vue.http.get(`${baseUrl}/ToolSubType/GetById?Id=${id}`)
export const getTool = (id) => Vue.http.get(`${baseUrl}/Tool/GetById?Id=${id}`)
export const getPoint = (id) => Vue.http.get(`${baseUrl}/Point/GetById?Id=${id}`)
export const getPointDefinition = (id) => Vue.http.get(`${baseUrl}/Definition/GetById?Id=${id}`)
export const getUnit = (id) => Vue.http.get(`${baseUrl}/Unit/GetById?Id=${id}`)
export const getCurrency = (id) => Vue.http.get(`${baseUrl}/Unit/GetUnits?unitTypeId=${id}`)
export const getContract = (id) => Vue.http.get(`${baseUrl}/Contract/GetContractWithUser?Id=${id}`)
export const getContractSection = (id) => Vue.http.get(`${baseUrl}/ContractSection/GetById?Id=${id}`)
export const getBrand = (id) => Vue.http.get(`${baseUrl}/Brand/GetById?Id=${id}`)
export const getToolBrand = (id) => Vue.http.get(`${baseUrl}/ToolBrand/GetById?Id=${id}`)
export const getCapacityEquipmentBrand = (id) => Vue.http.get(`${baseUrl}/CapacityEquipmentBrand/GetById?Id=${id}`)
export const getModel = (id) => Vue.http.get(`${baseUrl}/Model/GetById?Id=${id}`)
export const getToolModel = (id) => Vue.http.get(`${baseUrl}/ToolModel/GetById?Id=${id}`)
export const getCapacityEquipmentModel = (id) => Vue.http.get(`${baseUrl}/CapacityEquipmentModel/GetById?Id=${id}`)
export const getCurrencyUnit = (unitTypeName) =>
  Vue.http.get(`${baseUrl}/Unit/GetUnitsByName`, { params: { unitTypeName } })
export const getContractSla = (id) => Vue.http.get(`${baseUrl}/ContractSla/GetById?Id=${id}`)
export const getContractFile = (id) => Vue.http.get(`${baseUrl}/ContractFile/GetContractFileView?Id=${id}`)
export const getFormStatusSummary = (workOrderId, formId) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetFormStatusSummary`, { params: { workOrderId, formId } })
export const getCampusLogo = (workId) => Vue.http.get(`${baseUrl}/Campus/GetCampusLogo?workOrderId=${workId}`)
export const getWorkOrderApprovalsInformation = (formId, workOrderId) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetWorkOrderApprovalsInformation?formId=${formId}&workOrderId=${workOrderId}`)
// #endregion Modal Get Methods

// #region Save Methods
export const saveActorRolesAndUsers = (data) => Vue.http.post(`${baseUrl}/SecurityActor/SaveActorRolesAndUsers`, data)
export const saveContinent = (data) => Vue.http.post(`${baseUrl}/Continent/Save`, data)
export const saveCountry = (data) => Vue.http.post(`${baseUrl}/Country/Save`, data)
export const saveCity = (data) => Vue.http.post(`${baseUrl}/City/Save`, data)
export const saveDistrict = (data) => Vue.http.post(`${baseUrl}/District/Save`, data)
export const saveCampus = (data) => Vue.http.post(`${baseUrl}/Campus/Save`, data)
export const saveBuilding = (data) => Vue.http.post(`${baseUrl}/Building/Save`, data)
export const saveRoom = (data) => Vue.http.post(`${baseUrl}/Room/Save`, data)
export const saveRoomType = (data) => Vue.http.post(`${baseUrl}/RoomType/Save`, data)
export const saveRoomSubType = (data) => Vue.http.post(`${baseUrl}/RoomSubType/Save`, data)
export const saveDevice = (data) => Vue.http.post(`${baseUrl}/Device/Save`, data)
export const saveCapacityType = (data) => Vue.http.post(`${baseUrl}/CapacityType/Save`, data)
export const saveCapacityEquipment = (data) => Vue.http.post(`${baseUrl}/CapacityEquipment/Save`, data)
export const saveProductFamily = (data) => Vue.http.post(`${baseUrl}/ProductFamily/Save`, data)
export const saveVendor = (data) => Vue.http.post(`${baseUrl}/Vendor/Save`, data)
export const saveUserRole = (data) => Vue.http.post(`${baseUrl}/Role/Save`, data)
export const saveTrainingType = (data) => Vue.http.post(`${baseUrl}/TrainingType/Save`, data)
export const saveTraining = (data) => Vue.http.post(`${baseUrl}/Training/Save`, data)
export const saveUserType = (data) => Vue.http.post(`${baseUrl}/UserType/Save`, data)
export const saveUser = (data) => Vue.http.post(`${baseUrl}/User/Save`, data)
export const saveWorkOrderType = (data) => Vue.http.post(`${baseUrl}/WorkOrderType/Save`, data)
export const saveWorkOrderUser = (data) => Vue.http.post(`${baseUrl}/WorkOrderUser/Save`, data)
export const saveWorkCategory = (data) => Vue.http.post(`${baseUrl}/WorkCategory/Save`, data)
export const saveJob = (data) => Vue.http.post(`${baseUrl}/Job/Save`, data)
export const saveWork = (data) => Vue.http.post(`${baseUrl}/Work/Save`, data)
export const saveQualificationType = (data) => Vue.http.post(`${baseUrl}/QualificationType/Save`, data)
export const saveQualification = (data) => Vue.http.post(`${baseUrl}/Qualification/Save`, data)
export const saveInfrastructure = (data) => Vue.http.post(`${baseUrl}/Infrastructure/Save`, data)
export const saveInfrastructureType = (data) => Vue.http.post(`${baseUrl}/InfrastructureType/Save`, data)
export const saveInfrastructureGroup = (data) => Vue.http.post(`${baseUrl}/InfrastructureGroup/Save`, data)
export const saveShift = (data) => Vue.http.post(`${baseUrl}/Shift/Save`, data)
export const saveShiftType = (data) => Vue.http.post(`${baseUrl}/ShiftType/Save`, data)
export const saveMaintenancePeriod = (data) => Vue.http.post(`${baseUrl}/WorkPeriod/Save`, data)
export const saveMaintenancePlan = (data) => Vue.http.post(`${baseUrl}/MaintenancePlan/Save`, data)
export const saveWorkOrder = (data) => Vue.http.post(`${baseUrl}/WorkOrder/Save`, data)
export const saveIncident = (data) => Vue.http.post(`${baseUrl}/Incident/Save`, data)
export const transformToWorkOrder = (id) => Vue.http.post(`${baseUrl}/MaintenancePlan/TransformToWorkOrder`, { id })
export const saveSchedule = (data) => Vue.http.post(`${baseUrl}/Schedule/Save`, data)
export const saveProcedure = (data) => Vue.http.post(`${baseUrl}/Procedure/Save`, data)
export const saveTrainingMaterial = (data) => Vue.http.post(`${baseUrl}/TrainingMaterial/Save`, data)
export const saveDeviceConstraint = (data) => Vue.http.post(`${baseUrl}/ScheduleDevice/Save`, data)
export const saveWorkConstraint = (data) => Vue.http.post(`${baseUrl}/ScheduleWork/Save`, data)
export const saveRoomConstraint = (data) => Vue.http.post(`${baseUrl}/ScheduleRoom/Save`, data)
export const saveTrainingAssessment = (data) => Vue.http.post(`${baseUrl}/Assessment/Save`, data)
export const saveTrainingAssessmentType = (data) => Vue.http.post(`${baseUrl}/AssessmentType/Save`, data)
export const saveRaci = (data) => Vue.http.post(`${baseUrl}/WorkGroupRole/SaveRaci`, data)
export const saveUserTraining = (data) => Vue.http.post(`${baseUrl}/UserTraining/Save`, data)
export const saveUserOvertime = (data) => Vue.http.post(`${baseUrl}/UserOvertime/Save`, data)
export const saveUserExcuse = (data) => Vue.http.post(`${baseUrl}/UserExcuse/Save`, data)
export const saveUserAssessment = (data) => Vue.http.post(`${baseUrl}/UserAssessment/Save`, data)
export const saveWorkGroup = (data) => Vue.http.post(`${baseUrl}/WorkGroup/Save`, data)
export const saveRoleTraining = (data) => Vue.http.post(`${baseUrl}/TrainingRole/Save`, data)
export const saveNetAvailableWorkHours = (data) =>
  Vue.http.post(`${baseUrl}/MinimumStaff/SaveNetAvailableWorkHours`, data)
export const saveTurnOverRate = (data) => Vue.http.post(`${baseUrl}/MinimumStaff/SaveTurnOverRate`, data)
export const saveCoveragePosition = (data) => Vue.http.post(`${baseUrl}/MinimumStaff/SaveCoveragePosition`, data)
export const saveHourlyProcessingCapability = (data) =>
  Vue.http.post(`${baseUrl}/MinimumStaff/SaveHourlyProcessingCapability`, data)
export const saveVolumeInfluencedPosition = (data) =>
  Vue.http.post(`${baseUrl}/MinimumStaff/SaveVolumeInfluencedPosition`, data)
export const saveSecurityItem = (data) => Vue.http.post(`${baseUrl}/SecurityItem/Save`, data)
export const saveRoleGroup = (data) => Vue.http.post(`${baseUrl}/RoleGroup/Save`, data)
export const saveRoleGroupRole = (data) => Vue.http.post(`${baseUrl}/RoleGroupRole/Save`, data)
export const saveRoleGroupUser = (data) => Vue.http.post(`${baseUrl}/RoleGroupUser/Save`, data)
export const saveSecurityRoleItem = (data) => Vue.http.post(`${baseUrl}/SecurityRoleItem/Save`, data)
export const saveTrainingCondition = (data) => Vue.http.post(`${baseUrl}/TrainingCondition/Save`, data)
export const saveComponent = (data) => Vue.http.post(`${baseUrl}/Component/Save`, data)
export const saveComponentCheckItem = (data) => Vue.http.post(`${baseUrl}/ComponentCheckItem/Save`, data)
export const saveReferenceLibraryItem = (data) => Vue.http.post(`${baseUrl}/ReferenceLibrary/Save`, data)
export const saveDescription = (data) => Vue.http.post(`${baseUrl}/Description/Save`, data)
export const saveServiceProvider = (data) => Vue.http.post(`${baseUrl}/ServiceProvider/Save`, data)
export const saveFinancialItem = (data) => Vue.http.post(`${baseUrl}/Financial/Save`, data)
export const saveMassMaintenancePlan = (data) =>
  Vue.http.post(`${baseUrl}/MaintenancePlan/SaveMassMaintenancePlan`, data)
export const saveWorkOrderStatusDescription = (data) =>
  Vue.http.post(`${baseUrl}/WorkOrderStatusDescription/Save`, data)
export const saveForm = (data) => Vue.http.post(`${baseUrl}/Form/Save`, data)
export const saveQuestion = (data) => Vue.http.post(`${baseUrl}/Question/Save`, data)
export const saveQuestionGroup = (data) => Vue.http.post(`${baseUrl}/QuestionGroup/Save`, data)
export const saveQuestionProcess = (data) => Vue.http.post(`${baseUrl}/QuestionProcess/Save`, data)
export const saveQAQCChecked = (id) => Vue.http.post(`${baseUrl}/QAQCWorkOrder/CheckQAQC?id=${id}`)
export const saveFormProcessFile = (data) => Vue.http.post(`${baseUrl}/FormProcessFile/Save`, data)
export const saveToolType = (data) => Vue.http.post(`${baseUrl}/ToolType/Save`, data)
export const saveToolSubType = (data) => Vue.http.post(`${baseUrl}/ToolSubType/Save`, data)
export const saveWarehouse = (data) => Vue.http.post(`${baseUrl}/Warehouse/Save`, data)
export const saveTool = (data) => Vue.http.post(`${baseUrl}/Tool/Save`, data)
export const saveAllTools = (data) => Vue.http.post(`${baseUrl}/Tool/SaveAll`, data)
export const saveReservation = (data) => Vue.http.post(`${baseUrl}/Reservation/Save`, data)
export const savePoint = (data) => Vue.http.post(`${baseUrl}/Point/Save`, data)
export const clonePoint = (data) => Vue.http.post(`${baseUrl}/Point/Clone`, data)
export const savePointDefinition = (data) => Vue.http.post(`${baseUrl}/Definition/Save`, data)
export const saveAction = (data) => Vue.http.post(`${baseUrl}/SecurityActionActor/Save`, data)
export const saveUnit = (data) => Vue.http.post(`${baseUrl}/Unit/Save`, data)
export const saveActorUser = (data) => Vue.http.post(`${baseUrl}/SecurityActorUser/Save`, data)
export const saveActorRole = (data) => Vue.http.post(`${baseUrl}/SecurityActorRole/Save`, data)
export const saveSecurityActionGroup = (data) => Vue.http.post(`${baseUrl}/SecurityActionGroup/Save`, data)
export const saveSecurityAction = (data) => Vue.http.post(`${baseUrl}/SecurityAction/Save`, data)
export const saveContract = (data) => Vue.http.post(`${baseUrl}/Contract/Save`, data)
export const saveContractSection = (data) => Vue.http.post(`${baseUrl}/ContractSection/Save`, data)
export const saveContractMaintenancePlan = (data) => Vue.http.post(`${baseUrl}/ContractMaintenance/Save`, data)
export const getContractMaintenancePlanList = (data) =>
  Vue.http.get(`${baseUrl}/ContractMaintenance/Getlist`, { params: data })
export const getContractMaintenancePlan = (id) => Vue.http.get(`${baseUrl}/ContractMaintenance/GetById?Id=${id}`)
export const deleteContractMaintenancePlan = (id) => Vue.http.post(`${baseUrl}/ContractMaintenance/Delete?id=${id}`)
export const cloneMassPoint = (data) => Vue.http.post(`${baseUrl}/Point/MassClone`, data)
export const saveBrand = (data) => Vue.http.post(`${baseUrl}/Brand/Save`, data)
export const workOrderSaveVendorWorkTime = (id) => Vue.http.post(`${baseUrl}/WorkOrder/SaveVendorWorkTime`, data)
/* export const saveToolBrand = (data) => {
    return Vue.http.post(`${baseUrl}/ToolBrand/Save`, data);
}; */
export const saveModel = (data) => Vue.http.post(`${baseUrl}/Model/Save`, data)
export const saveContractSla = (data) => Vue.http.post(`${baseUrl}/ContractSla/Save`, data)
export const saveContractFile = (data) => Vue.http.post(`${baseUrl}/ContractFile/Save`, data)
/* export const saveToolModel = (data) => {
    return Vue.http.post(`${baseUrl}/ToolModel/Save`, data);
}; */
// #endregion Save Methods

// #region Update Methods
export const updateMaintenancePlan = (data) => Vue.http.post(`${baseUrl}/MaintenancePlan/UpdateMaintenance`, data)
export const changePassword = (data) => Vue.http.post(`${baseUrl}/User/ChangePassword`, data)
export const updateWorkOrderStatus = (data) => Vue.http.post(`${baseUrl}/WorkOrder/UpdateStatus`, data)
export const updateReservation = (data) => Vue.http.post(`${baseUrl}/Reservation/Update`, data)
export const lendReservation = (data) => Vue.http.post(`${baseUrl}/Reservation/Lend`, data)
export const returnReservation = (data) => Vue.http.post(`${baseUrl}/Reservation/Return`, data)
// #endregion Update Methods

// #region Delete Methods
export const deleteContinent = (id) => Vue.http.post(`${baseUrl}/Continent/Delete?id=${id}`)
export const deleteCountry = (id) => Vue.http.post(`${baseUrl}/Country/Delete?id=${id}`)
export const deleteCity = (id) => Vue.http.post(`${baseUrl}/City/Delete?id=${id}`)
export const deleteDistrict = (id) => Vue.http.post(`${baseUrl}/District/Delete?id=${id}`)
export const deleteCampus = (id) => Vue.http.post(`${baseUrl}/Campus/Delete?id=${id}`)
export const deleteBuilding = (id) => Vue.http.post(`${baseUrl}/Building/Delete?id=${id}`)
export const deleteFloor = (id) => Vue.http.post(`${baseUrl}/Floor/Delete?id=${id}`)
export const deleteRoom = (id) => Vue.http.post(`${baseUrl}/Room/Delete?id=${id}`)
export const deleteRoomType = (id) => Vue.http.post(`${baseUrl}/RoomType/Delete?id=${id}`)
export const deleteRoomSubType = (id) => Vue.http.post(`${baseUrl}/RoomSubType/Delete?id=${id}`)
export const deleteDevice = (id) => Vue.http.post(`${baseUrl}/Device/Delete?id=${id}`)
export const deleteCapacityEquipment = (id) => Vue.http.post(`${baseUrl}/CapacityEquipment/Delete?id=${id}`)
export const deleteCapacityType = (id) => Vue.http.post(`${baseUrl}/CapacityType/Delete?id=${id}`)
export const deleteProductFamily = (id) => Vue.http.post(`${baseUrl}/ProductFamily/Delete?id=${id}`)
export const deleteVendor = (id) => Vue.http.post(`${baseUrl}/Vendor/Delete?id=${id}`)
export const deleteUserRole = (id) => Vue.http.post(`${baseUrl}/Role/Delete?id=${id}`)
export const deleteTrainingType = (id) => Vue.http.post(`${baseUrl}/TrainingType/Delete?id=${id}`)
export const deleteTraining = (id) => Vue.http.post(`${baseUrl}/Training/Delete?id=${id}`)
export const deleteUserType = (id) => Vue.http.post(`${baseUrl}/UserType/Delete?id=${id}`)
export const deleteUser = (id) => Vue.http.post(`${baseUrl}/User/Delete?id=${id}`)
export const deleteWorkOrderType = (id) => Vue.http.post(`${baseUrl}/WorkOrderType/Delete?id=${id}`)
export const deleteWorkCategory = (id) => Vue.http.post(`${baseUrl}/WorkCategory/Delete?id=${id}`)
export const deleteJob = (id) => Vue.http.post(`${baseUrl}/Job/Delete?id=${id}`)
export const deleteWork = (id) => Vue.http.post(`${baseUrl}/Work/Delete?id=${id}`)
export const deleteQualificationType = (id) => Vue.http.post(`${baseUrl}/QualificationType/Delete?id=${id}`)
export const deleteQualification = (id) => Vue.http.post(`${baseUrl}/Qualification/Delete?id=${id}`)
export const deleteInfrastructure = (id) => Vue.http.post(`${baseUrl}/Infrastructure/Delete?id=${id}`)
export const deleteInfrastructureType = (id) => Vue.http.post(`${baseUrl}/InfrastructureType/Delete?id=${id}`)
export const deleteInfrastructureGroup = (id) => Vue.http.post(`${baseUrl}/InfrastructureGroup/Delete?id=${id}`)
export const deleteShift = (id) => Vue.http.post(`${baseUrl}/Shift/Delete?id=${id}`)
export const deleteShiftType = (id) => Vue.http.post(`${baseUrl}/ShiftType/Delete?id=${id}`)
export const deleteMaintenancePeriod = (id) => Vue.http.post(`${baseUrl}/WorkPeriod/Delete?id=${id}`)
export const deleteMaintenancePlan = (id) => Vue.http.post(`${baseUrl}/MaintenancePlan/Delete?id=${id}`)
export const deleteMaintenanceGroup = (data) => Vue.http.post(`${baseUrl}/MaintenanceGroup/DeleteWithDescription`, data)
export const deleteWorkOrder = (id) => Vue.http.post(`${baseUrl}/WorkOrder/Delete?id=${id}`)
export const deleteIncident = (id) => Vue.http.post(`${baseUrl}/Incident/Delete?id=${id}`)
export const deleteSchedule = (id) => Vue.http.post(`${baseUrl}/Schedule/Delete?id=${id}`)
export const deleteProcedure = (id) => Vue.http.post(`${baseUrl}/Procedure/Delete?id=${id}`)
export const deleteTrainingMaterial = (id) => Vue.http.post(`${baseUrl}/TrainingMaterial/Delete?id=${id}`)
export const deleteDeviceConstraint = (id) => Vue.http.post(`${baseUrl}/ScheduleDevice/Delete?id=${id}`)
export const deleteWorkConstraint = (id) => Vue.http.post(`${baseUrl}/ScheduleWork/Delete?id=${id}`)
export const deleteRoomConstraint = (id) => Vue.http.post(`${baseUrl}/ScheduleRoom/Delete?id=${id}`)
export const deleteTrainingAssessment = (id) => Vue.http.post(`${baseUrl}/Assessment/Delete?id=${id}`)
export const deleteTrainingAssessmentType = (id) => Vue.http.post(`${baseUrl}/AssessmentType/Delete?id=${id}`)
export const deleteUserTraining = (id) => Vue.http.post(`${baseUrl}/UserTraining/Delete?id=${id}`)
export const deleteUserOvertime = (id) => Vue.http.post(`${baseUrl}/UserOvertime/Delete?id=${id}`)
export const deleteUserExcuse = (id) => Vue.http.post(`${baseUrl}/UserExcuse/Delete?id=${id}`)
export const deleteUserAssessment = (id) => Vue.http.post(`${baseUrl}/UserAssessment/Delete?id=${id}`)
export const deleteWorkGroup = (id) => Vue.http.post(`${baseUrl}/WorkGroup/Delete?id=${id}`)
export const deleteSecurityItem = (id) => Vue.http.post(`${baseUrl}/SecurityItem/Delete?id=${id}`)
export const deleteWithRoleGroupAndRoleId = (data) =>
  Vue.http.post(`${baseUrl}/RoleGroupRole/DeleteWithRoleGroupAndRoleId`, data)
export const deleteWithRoleGroupAndUserId = (data) =>
  Vue.http.post(`${baseUrl}/RoleGroupUser/DeleteWithRoleGroupAndUserId`, data)
export const deleteReferenceLibraryItem = (id) => Vue.http.post(`${baseUrl}/ReferenceLibrary/Delete?id=${id}`)
export const deleteDescription = (id) => Vue.http.post(`${baseUrl}/Description/Delete?id=${id}`)
export const deleteServiceProviderItem = (id) => Vue.http.post(`${baseUrl}/ServiceProvider/Delete?id=${id}`)
export const deleteFinancialItem = (id) => Vue.http.post(`${baseUrl}/Financial/Delete?id=${id}`)
export const deleteComponent = (id) => Vue.http.post(`${baseUrl}/Component/Delete?id=${id}`)
export const deleteComponentCheckItem = (id) => Vue.http.post(`${baseUrl}/ComponentCheckItem/Delete?id=${id}`)
export const deleteMassMaintenancePlan = (id) =>
  Vue.http.post(`${baseUrl}/MaintenancePlan/DeleteMassMaintenancePlan/${id}`)
export const deleteWorkOrderStatusDescription = (id) =>
  Vue.http.post(`${baseUrl}/WorkOrderStatusDescription/Delete?id=${id}`)
export const deleteTurnOver = (id) => Vue.http.post(`${baseUrl}/MinimumStaff/DeleteTurnOver/${id}`)
export const deleteWorkOrderUsers = (data) => Vue.http.post(`${baseUrl}/WorkOrderUser/DeleteWorkOrderUsers`, data)
export const deleteQuestion = (id) => Vue.http.post(`${baseUrl}/Question/Delete?id=${id}`)
export const deleteQuestionGroup = (id) => Vue.http.post(`${baseUrl}/QuestionGroup/Delete?id=${id}`)
/* export const deleteSelection = (id) => {
    return Vue.http.post(`${baseUrl}/Selection/Delete?id=${id}`);
}; */
export const deleteToolType = (id) => Vue.http.post(`${baseUrl}/ToolType/Delete?id=${id}`)
export const deleteToolSubType = (id) => Vue.http.post(`${baseUrl}/ToolSubType/Delete?id=${id}`)
export const deleteToolBrand = (id) => Vue.http.post(`${baseUrl}/ToolBrand/Delete?id=${id}`)
export const deleteToolModel = (id) => Vue.http.post(`${baseUrl}/ToolModel/Delete?id=${id}`)
export const deleteWarehouse = (id) => Vue.http.post(`${baseUrl}/Warehouse/Delete?id=${id}`)
export const deleteTool = (id) => Vue.http.post(`${baseUrl}/Tool/Delete?id=${id}`)
export const deleteReservation = (id) => Vue.http.post(`${baseUrl}/Reservation/Delete?id=${id}`)
export const deleteRoleGroup = (id) => Vue.http.post(`${baseUrl}/RoleGroup/Delete?id=${id}`)
export const deletePoint = (id) => Vue.http.post(`${baseUrl}/Point/Delete?id=${id}`)
export const deletePointDefinition = (id) => Vue.http.post(`${baseUrl}/Definition/Delete?id=${id}`)
export const deleteUnit = (id) => Vue.http.post(`${baseUrl}/Unit/Delete?id=${id}`)
export const deleteWorkOrderHtmlContent = (id) => Vue.http.post(`${baseUrl}/WorkOrderHtmlContent/Delete?id=${id}`)
export const deleteBrand = (id) => Vue.http.post(`${baseUrl}/Brand/Delete?id=${id}`)
/* export const deleteToolBrand = (id) => {
    return Vue.http.post(`${baseUrl}/ToolBrand/Delete?id=${id}`);
}; */
export const deleteModel = (id) => Vue.http.post(`${baseUrl}/Model/Delete?id=${id}`)
/* export const deleteToolModel = (id) => {
    return Vue.http.post(`${baseUrl}/ToolModel/Delete?id=${id}`);
}; */
export const removeTempPoint = (questionId) =>
  Vue.http.get(`${baseUrl}/Question/RemoveTempPoint/`, { params: { questionId } })
export const deleteContractSection = (id) => Vue.http.post(`${baseUrl}/ContractSection/Delete?id=${id}`)
export const deleteContractSla = (id) => Vue.http.post(`${baseUrl}/ContractSla/Delete?id=${id}`)
export const deleteContractFile = (id) => Vue.http.post(`${baseUrl}/ContractFile/Delete?id=${id}`)
export const deleteContract = (id) => Vue.http.post(`${baseUrl}/Contract/Delete?id=${id}`)
// #endregion Delete Methods

// #region Other Methods
export const getLanguages = () => Vue.http.get(`${baseUrl}/Common/GetLanguages`)
export const getCapctcha = () => Vue.http.get(`${baseUrl}/Security/GetCaptcha`)
export const parametersList = (data) => Vue.http.get(`${baseUrl}/parameter/GetList`, { params: data })
export const login = (data) => Vue.http.post(`${baseUrl}/Access/Token`, data)
export const keepAlive = (data) => true
export const getOrganizationScheme = (id) => Vue.http.get(`${baseUrl}/Organization/GetSchema?campusId=${id}`)
export const saveFile = (data) =>
  Vue.http.post(`${baseUrl}/File/SaveFile`, data, {
    progress(e) {
      if (e.lengthComputable) {
        // console.log(e.loaded / e.total * 100);
      }
    },
  })
export const getFile = (data) => Vue.http.get(`${baseUrl}/File/GetFile`, { params: data })
export const getFileTypeList = () => Vue.http.get(`${baseUrl}/FileType/GetList`)
export const sendForgotPasswordEmail = (data) => Vue.http.post(`${baseUrl}/User/ForgotPassword`, data)
export const getWorkOrderListById = (data) => Vue.http.get(`${baseUrl}/WorkOrder/GetListById`, { params: data })
export const getWorkOrderListByParentId = (parentId) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetListByParentId?ParentId=${parentId}`)
export const getMyWorkOrders = (data) => Vue.http.get(`${baseUrl}/WorkOrder/GetMyWorkOrders`, { params: data })
export const getWorkOrdersBetweenDates = (startDate, endDate) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetWorkOrdersBetweenDates?startDate=${startDate}&endDate=${endDate}`)
export const getTodayWorkOrders = () => Vue.http.get(`${baseUrl}/WorkOrder/GetTodayWorkOrders`)
export const getUserInfo = (id) => Vue.http.get(`${baseUrl}/User/GetUserInfo`, { params: { Id: id } })
export const getWorkOrderDetail = (id) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetWorkOrderDetail`, { params: { Id: id } })
export const getWorkOrderSummary = (id) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetWorkOrderSummary`, { params: { Id: id } })
export const getWorkOrderPropertyCounts = (workOrderId) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetPropertyCounts`, { params: { workOrderId } })
export const getWorkOrderAssignedUsers = (workOrderId, userPositionId) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetAssignedUsers`, { params: { workOrderId, userPositionId } })
export const getFormStatusSummaryList = (workOrderId) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetFormStatusSummaryList`, { params: { workOrderId } })
export const getWorkOrderProcedures = (workOrderId) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetProcedures`, { params: { workOrderId } })
export const getWorkOrderHistory = (workOrderId) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetWorkOrderHistory`, { params: { workOrderId } })
export const getWorkOrderWithRelatedWorkOrders = (id) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetWorkOrderWithRelatedWorkOrders`, { params: { Id: id } })
export const getNetAvailableWorkHour = () => Vue.http.get(`${baseUrl}/MinimumStaff/GetNetAvailableWorkHour`)
export const getTurnOvers = () => Vue.http.get(`${baseUrl}/MinimumStaff/GetTurnOvers`)
export const getRoleTrainingList = () => Vue.http.get(`${baseUrl}/TrainingRole/GetList`)
export const getSecurityRoleItem = (data) => Vue.http.get(`${baseUrl}/SecurityRoleItem/GetList`, { params: data })
export const getUserTrainingsLastIssued = () => Vue.http.get(`${baseUrl}/UserTraining/GetLastIssued`)
export const getWorkOrderSuitableStatuses = (workOrderId) =>
  Vue.http.get(`${baseUrl}/WorkOrderStatus/GetWorkOrderSuitableStatuses?workOrderId=${workOrderId}`)
export const getCampusWithParents = (data) => Vue.http.get(`${baseUrl}/Campus/GetList`, { params: data })
export const resetPassword = (data) => Vue.http.post(`${baseUrl}/User/ResetPassword`, data)
export const deviceFromToList = (id) => Vue.http.get(`${baseUrl}/Device/GetFromToList?buildingId=${id}`)
export const getInfrastructuresWithCapacityTypes = () => Vue.http.get(`${baseUrl}/Infrastructure/GetWithCapacityTypes`)
export const getCampusListWithRooms = () => Vue.http.get(`${baseUrl}/Campus/GetWithRooms`)
export const getDevicesFromModelId = (data) => Vue.http.get(`${baseUrl}/Device/GetAllDevicesByModel`, { params: data })
export const getLastTrainingsForUsers = () => Vue.http.get(`${baseUrl}/Training/GetLastTrainingsForUsers?daysToCheck=5`)
export const getMostNeedTrainings = (data) => Vue.http.get(`${baseUrl}/Training/GetMostNeedTrainings`, { params: data })
export const getContractDashboardUpcomingExpire = () =>
  Vue.http.get(`${baseUrl}/Contract/GetContractDashboardUpcomingExpire`)
export const getSparePartBelowCriticalStockCount = () =>
  Vue.http.get(`${baseUrl}/SparePart/GetSparePartBelowCriticalStockCount`)
export const getTrainingDemands = (data) => Vue.http.get(`${baseUrl}/Training/GetTrainingDemands`, { params: data })
export const getDeviceFilter = (data) => Vue.http.get(`${baseUrl}/Device/Filter`, { params: data })
export const approveWorkOrder = (data) => Vue.http.post(`${baseUrl}/WorkOrder/ApproveWorkOrder`, data)
export const setWorkOrderSpentMinutes = (id, spentMinutes) =>
  Vue.http.get(`${baseUrl}/WorkOrder/SetSpentMinutes`, { params: { id, spentMinutes } })
export const getAverageTurnOver = () => Vue.http.get(`${baseUrl}/MinimumStaff/GetAverageTurnOver`)
export const getCoveragePosition = () => Vue.http.get(`${baseUrl}/MinimumStaff/GetCoveragePosition`)
export const getHourlyProcessingCapability = () => Vue.http.get(`${baseUrl}/MinimumStaff/GetHourlyProcessingCapability`)
export const getVolumeInfluencedPosition = () => Vue.http.get(`${baseUrl}/MinimumStaff/GetVolumeInfluencedPosition`)
export const getFormSuitableStatuses = (formId) =>
  Vue.http.get(`${baseUrl}/FormStatus/GetFormSuitableStatuses?formId=${formId}`)
export const cloneForm = (data) => Vue.http.post(`${baseUrl}/Form/Clone`, data)
export const getColors = () => Vue.http.get(`${baseUrl}/Common/GetColorList`)
export const reorderQuestionGroups = (data) => Vue.http.post(`${baseUrl}/QuestionGroup/Reorder`, data)
export const reorderQuestions = (data) => Vue.http.post(`${baseUrl}/Question/Reorder`, data)
export const reorderSelections = (data) => Vue.http.post(`${baseUrl}/Selection/Reorder`, data)
export const reorderContractSections = (data) => Vue.http.post(`${baseUrl}/ContractSection/Reorder`, data)
export const startFormProcess = (data) => Vue.http.post(`${baseUrl}/FormProcess/StartProcess`, data)
export const endFormProcess = (data) => Vue.http.post(`${baseUrl}/FormProcess/EndProcess`, data)
export const validateWorkSchedule = (data) => Vue.http.get(`${baseUrl}/ScheduleWork/Validate`, { params: data })
export const validateDeviceSchedule = (data) => Vue.http.get(`${baseUrl}/ScheduleDevice/Validate`, { params: data })
export const validateRoomSchedule = (data) => Vue.http.get(`${baseUrl}/ScheduleRoom/Validate`, { params: data })
export const upgradeFormVersion = (data) => Vue.http.post(`${baseUrl}/Form/UpgradeVersion`, data)
export const getToolHistoryList = (data) => Vue.http.get(`${baseUrl}/ToolHistory/GetList`, { params: data })
export const getToolLogTypes = (data) => Vue.http.get(`${baseUrl}/ToolHistory/GetLogTypes`, { params: data })
export const getFormQuestionTypeListByPointType = (data) =>
  Vue.http.get(`${baseUrl}/FormQuestionType/GetListByPointType`, { params: data })
export const getRoomsWithCapacityEquipments = () => Vue.http.get(`${baseUrl}/Room/GetRoomsWithCapacityEquipments`)
export const getRoomWithPoints = (data) => Vue.http.get(`${baseUrl}/Room/GetRoomWithPoints`, { params: data })
export const getRoomsWithDevices = (data) => Vue.http.post(`${baseUrl}/Room/GetRoomsWithDevices`, data)
/* todo remove method
export const updateFeedback = (formProcessId, feedback) => {
    return Vue.http.post(`${baseUrl}/FormProcess/UpdateFeedback`, {formProcessId, feedback});
}; */
export const getWorkOrderHtmlContentList = (data) =>
  Vue.http.get(`${baseUrl}/WorkOrderHtmlContent/GetList`, { params: { ...data, baseUrl } })
export const getWorkOrderHtmlContentView = (data) =>
  Vue.http.get(`${baseUrl}/WorkOrderHtmlContent/GetView`, { params: { ...data, baseUrl } })
export const saveWorkOrderHtmlContent = (data) => Vue.http.post(`${baseUrl}/WorkOrderHtmlContent/Save`, data)
export const getWorkOrderHtmlContent = (id) =>
  Vue.http.get(`${baseUrl}/WorkOrderHtmlContent/GetWithBaseUrl`, { params: { id, baseUrl } })
export const getFormProcess = (formId, workOrderId) =>
  Vue.http.get(`${baseUrl}/FormProcess/GetFormProcess`, { params: { formId, workOrderId } })
export const getDeviceTypeList = () => Vue.http.get(`${baseUrl}/DeviceType/GetList`)
export const getDeviceHistoryByDeviceId = (deviceId) =>
  Vue.http.get(`${baseUrl}/DeviceHistory/GetByDeviceId?deviceId=${deviceId}`)
export const saveDeviceType = (data) => Vue.http.post(`${baseUrl}/DeviceType/Save`, data)
export const sendBackWOPendingApproval = (data) =>
  Vue.http.post(`${baseUrl}/WorkOrder/SendBackFromPendingApproval`, data)
export const changePoint = (questionId, pointId) =>
  Vue.http.post(`${baseUrl}/Question/ChangePoint`, { questionId, pointId })
export const getDeviceType = (id) => Vue.http.get(`${baseUrl}/DeviceType/GetById?Id=${id}`)
export const deleteDeviceType = (id) => Vue.http.post(`${baseUrl}/DeviceType/Delete?id=${id}`)
export const getCapacityEquipmentListForTreeView = (id, typeId) =>
  Vue.http.get(`${baseUrl}/CapacityEquipment/GetListForTreeView`, { params: { id, typeId } })
export const getRoomListForTreeView = () => Vue.http.get(`${baseUrl}/Room/GetListForTreeView`)
export const getContractFileListByContractId = (ContractId) =>
  Vue.http.get(`${baseUrl}/ContractFile/GetList?contractId=${ContractId}`)
export const getWorkOrderConvertibleStatusesByWorkOrderId = (workOrderId) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetWorkOrderConvertibleStatuses?workOrderId=${workOrderId}`)
export const getNextForm = (workOrderId, currentFormId) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetNextForm?workOrderId=${workOrderId}&currentFormId=${currentFormId}`)
export const getAvailableShiftDeletableDayCount = () =>
  Vue.http.get(`${baseUrl}/Shift/GetAvailableShiftDeletableDayCount`)
// #endregion Other Methods

// #region Other Methods
export const generateGapReport = (startDate, endDate) =>
  Vue.http.get(`${baseUrl}/Report/GenerateGapReport`, { params: { startDate, endDate } })
export const getRoundGapReport = (startDate, endDate) =>
  Vue.http.get(`${baseUrl}/Report/GetRoundGapReport`, { params: { startDate, endDate } })
export const getRoundGapReportForStaff = (startDate, endDate) =>
  Vue.http.get(`${baseUrl}/Report/GetRoundGapReportForStaff`, { params: { startDate, endDate } })
export const getStaffRoundCountsReport = (startDate, endDate) =>
  Vue.http.get(`${baseUrl}/Report/GetStaffRoundCountsReport`, { params: { startDate, endDate } })
export const getVendorWorkTimeReport = (startDate, endDate) =>
  Vue.http.get(`${baseUrl}/Report/GetVendorWorkTimeReport`, { params: { startDate, endDate } })
export const getRoundGapReportForDevice = (startDate, endDate) =>
  Vue.http.get(`${baseUrl}/Report/GetRoundGapReportForDevice`, { params: { startDate, endDate } })
export const getRoundDurationStaffReport = (startDate, endDate) =>
  Vue.http.get(`${baseUrl}/Report/GetRoundDurationStaffReport`, { params: { startDate, endDate } })
export const getRoundGapReportForPoints = (startDate, endDate) =>
  Vue.http.get(`${baseUrl}/Report/GetGapReportForPoints`, { params: { startDate, endDate } })
export const getWorkOrderReport = (startDate, endDate) =>
  Vue.http.get(`${baseUrl}/Report/GetWorkOrderReport`, { params: { startDate, endDate } })
export const getIncidentReport = (startDate, endDate) =>
  Vue.http.get(`${baseUrl}/Report/GetIncidentReport`, { params: { startDate, endDate } })
export const getPeriodicMaintenanceReport = (startDate, endDate) =>
  Vue.http.get(`${baseUrl}/WorkOrder/GetPeriodicMaintenanceReport`, { params: { startDate, endDate } })
export const getDeviceCountWithScpFormCount = () =>
  Vue.http.get(`${baseUrl}/CapacityEquipment/GetDeviceCountWithScpFormCount`)
export const getCapacityEquipmentCountWithInfrastructure = () =>
  Vue.http.get(`${baseUrl}/Device/GetCapacityEquipmentCountWithInfrastructure`)
export const getLdapUser = (username) => Vue.http.get(`${baseUrl}/User/GetLdapUser`, { params: { username } })
export const getAllAssetReport = () => {
  return Vue.http.get(`${baseUrl}/Report/GetAssetsReport`)
}
export const getSparePartSettings = () => {
  return Vue.http.get(`${baseUrl}/SparePartSettings`)
}
export const getOverdueWorkOrders = () => {
  return Vue.http.get(`${baseUrl}/WorkOrder/GetOverdueWorkOrders`)
}
// #endregion Other Methods

export const GetEntityQuery = (entityType, odataQuery) =>
  Vue.http.get(`${baseUrl}/odata/${entityType}`, { params: odataQuery })

export const GetMetadataOfEntity = () => Vue.http.get(`${baseUrl}/odata/$metadata?$format=json`)
export const GetPreventiveMaintenanceSchedules = (data) =>
  Vue.http.post(`${baseUrl}/PreventiveMaintenanceSchedule/Get`, data)
export const maintenancePlanGetById = (id) => Vue.http.get(`${baseUrl}/MaintenancePlan/GetById?id=${id}`)
export const preventiveMaintenanceScheduleSaveFilter = (data) => Vue.http.post(`${baseUrl}/PreventiveMaintenanceSchedule/SaveFilter`, data);
export const preventiveMaintenanceScheduleGetFilters = () => Vue.http.get(`${baseUrl}/PreventiveMaintenanceSchedule/GetFilters`);
export const preventiveMaintenanceScheduleDeleteFilter = (id) => Vue.http.post(`${baseUrl}/PreventiveMaintenanceSchedule/DeleteFilter?id=${id}`);
export const approveWorkOrderTraining = (data) => Vue.http.post(`${baseUrl}/WorkOrder/ApproveWorkOrderTraining`, data);
export const workOrderTrainingFileGetAllByWorkOrderId = (workOrderId) => Vue.http.get(`${baseUrl}/WorkOrderTrainingFile/GetAllByWorkOrderId?workOrderId=${workOrderId}`);
export const fileGetFile = (id) => Vue.http.get(`${baseUrl}/File/GetFile?id=${id}&isContainsData=true`); 
export const saveUserDocumentation = (data) => Vue.http.post(`${baseUrl}/UserDocument/Save`, data);
export const getUserDocumentList = (data) => Vue.http.get(`${baseUrl}/UserDocument/GetDocumentUserId`, { params: data });
export const deleteUserDocument = (id) => Vue.http.post(`${baseUrl}/UserDocument/Delete?id=${id}`);
export const getUserWorkOrderTrainingList = (data) => Vue.http.get(`${baseUrl}/UserTraining/GetListByUserId`, { params: data });
export const saveWorkOrderTrainingFile = (data) => Vue.http.post(`${baseUrl}/WorkOrderTrainingFile/Save`, data)
export const deleteWorkOrderTrainingDocument = (id) => Vue.http.post(`${baseUrl}/WorkOrderTrainingFile/Delete?id=${id}`);
