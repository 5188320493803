import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import * as api from '../../services/api';
import Select2 from '../../../components/shared/select2.vue';
import * as blockui from '../../../helpers/block-ui';
import * as swal from '../../../helpers/swal';

export default {
    props: {
        user: {
          default() {
            return {};
          },
          type: Object,
        },
        isUpdate: {
          default: false,
          type: Boolean,
        },
        refreshDocumentGrid: { default: false, type: Boolean },
      },
    data() {
        return {
            //userDocumentList: {},
        };
    },
    components: {
        select2: Select2,
    },
    computed: {
      ...mapState({ 
        userDocumentList: (state) => state.training.userDocumentList
       }),
  },
    created() {
    },
    mounted() {
      const self = this;
      self.$store.dispatch('getUserDocumentList', { userId: self.user.Id });
      mApp.initScrollers();
    },

    methods: {
      downloadFile(fileName, base64Content) {
        if (!base64Content) {
          alert('Bu dosya indirilemez (Base64 içeriği yok).')
          return
        }
        const binaryString = atob(base64Content)
        const len = binaryString.length
        const bytes = new Uint8Array(len)
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i)
        }
        const blob = new Blob([bytes.buffer], {
          type: 'application/octet-stream',
        })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        link.click()
        URL.revokeObjectURL(url)
      },

      deleteItem(index) {
        const self = this;
        const id = self.userDocumentList[index].Id;
        const name = self.userDocumentList[index].Name;
        swal.check(self, name).then((e) => {
          if (e.value) {
            api.deleteUserDocument(id).then((response) => {
              if (response.body.IsSuccess) {
                toastr.success(self.translate('delete_successfully'));
                self.$store.dispatch('getUserDocumentList', { userId: self.user.Id });
              } else {
                toastr.error(response.body.Message);
              }
            });
          }
        });
      },

    // async initData() {
    //     api.getUserDocumentList({ userId: this.user.Id }).then((res) => {
    //         this.userDocumentList = res.body;
    //     });
    // },
    },
    watch: {
    userDocumentList(e) {
      if (e) {
        Vue.set(this.$parent, 'userDocumentationCount', e.length);
      }
    },
  //   refreshDocumentGrid(e) {
  //     if (e) {
  //       this.initData();  }
  // },
},
};