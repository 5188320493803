import Vue from 'vue'
import { mapState } from 'vuex'
import * as api from '../services/api'
import Select2 from '../../components/shared/select2'
import * as blockui from '../../helpers/block-ui'
import * as dateFormat from '../../helpers/dateFormat'
import { mixinz } from '../global/mixinz'
import * as api2 from '../services/ClientApiAuto'
import form from '@/store/modules/form'

export default {
  props: ['userTraining', 'isUpdate'],
  mixins: [mixinz],
  data() {
    return {
      continent: {
        Value: '',
      },
      country: {
        Value: '',
      },
      selectedUserTraining: {
        IssueDate: '',
      },
      /* pickerDate: {
                format: 'DD.MM.YYYY',
            }, */
      instructorList: [],
      userListByRoles: {},
      isSpecificUser: false,
      examResultFile: null,
      certificateFile: null,
      fileIds: [],
      score: '',
    }
  },
  components: {
    select2: Select2,
  },
  computed: {
    ...mapState({
      userList: (state) => state.user.userList,
      trainingList: (state) => state.training.trainings,
    }),
    selectedDate: {
      get() {
        const self = this
        if (self.selectedUserTraining.IssueDate) {
          return moment(self.selectedUserTraining.IssueDate).format('DD.MM.YYYY')
        }
        return ''
      },
      set(newVal) {
        const self = this
        Vue.set(self.selectedUserTraining, 'IssueDate', moment(newVal, 'DD.MM.YYYY').toDate())
      },
    },
    trainings() {
      return this.trainingList.map((training) => ({ Text: training.Name, Value: training.Id }))
    },
  },
  created() {
    const self = this
    self.isSpecificUser = !!self.userTraining.UserId
    if (self.userTraining.TrainingId) {
      self.getInstructorList(self.userTraining.TrainingId)
    }
    self.selectedUserTraining = self.userTraining
  },
  async mounted() {
    const self = this
    window.$('form').validate()
    self.$nextTick(() => {
      Vue.loadAsteriks()
    })
    const userList = (await this.$globalApiClient.user.userGetUserListWithoutIncludes()).data
    userList.forEach((user) => {
      if (!self.userListByRoles[user.RoleId]) {
        self.userListByRoles[user.RoleId] = []
      }
      self.userListByRoles[user.RoleId].push({ Text: user.NameSurname, Value: user.Id })
    })

    this.$store.dispatch('getUserList')
    this.$store.dispatch('getTrainings')

    if (this.isUpdate && this.userTraining.UserTrainingFiles) {
      this.userTraining.UserTrainingFiles.forEach((fileItem) => {
        if (fileItem.TrainingFileType === 1) {
          const fileFromBackend = fileItem.File?.Name
          if (fileFromBackend) {
            this.certificateFile = { name: fileFromBackend }
          }
        } else if (fileItem.TrainingFileType === 2) {
          const fileFromBackend = fileItem.File?.Name
          if (fileFromBackend) {
            this.examResultFile = { name: fileFromBackend }
          }
        }
      })
    }
  },
  methods: {    

    onSubmit(e) {
      const self = this
      const form = window.$(e.target)
      if (form.valid()) {
        const formData = {}
        blockui.blockModal(self)
          $.map(form.serializeArray(), (item) => {
            Object.assign(formData, { [item.name]: item.value })
          })

          formData.IssueDate = dateFormat.postDateTimeFormatFromDateObject(self.selectedUserTraining.IssueDate)
          formData.Evaluation = self.selectedUserTraining.Evaluation
          if(!this.isUpdate){
            formData.UserTrainingFiles = [];
            self.fileIds.forEach((file) => {
              formData.UserTrainingFiles.push({
                UserTrainingId: 0,
                TrainingFileType: file.TrainingFileType,
                FileId: parseInt(file.FileId, 10),
              });
            });
          } else {
            let updatedTrainingFiles = this.userTraining.UserTrainingFiles.map((file) => ({
              UserTrainingId: this.userTraining.Id,
              TrainingFileType: file.TrainingFileType,
              FileId: parseInt(file.FileId, 10),
              Id: file.Id,
            }));
            //@ts-ignore
            this.fileIds.forEach(newItem=>{
              updatedTrainingFiles = updatedTrainingFiles.filter(item=>item.TrainingFileType !== newItem.TrainingFileType);
              updatedTrainingFiles.push({
                UserTrainingId: this.userTraining.Id,
                TrainingFileType: newItem.TrainingFileType,
                FileId: newItem.FileId
              });
            })
            formData.UserTrainingFiles = updatedTrainingFiles;
          }

          api.saveUserTraining(formData).then(
            (response) => {
              if (response.body.IsSuccess) {
                toastr.success(self.translate('save_successfully'))
                //self.$store.dispatch('getUserTrainingList', self.userTraining.UserId);
                self.$store.dispatch('getUserWorkOrderTrainingList', { userId: this.userTraining.UserId })
                self.closeModal()
              } else {
                toastr.error(response.body.Message)
              }
              blockui.unBlockModal()
            },
            (error) => {
              blockui.unBlockModal()
              console.error('API Hatası:', error)
            }
          )
      }
    },
    async handleFileChange(event, type) {
      const file = event.target.files[0]
      const fileType = type === 'examResult' ? 2 : type === 'certificate' ? 1 : null

      if (!fileType) {
        return
      }

      if (fileType === 2) {
        this.examResultFile = file || null
      } else if (fileType === 1) {
        this.certificateFile = file || null
      }

      try {
        const response = await this.$globalApiClient.file.fileSaveFile({ file: file, type: fileType })

        if (response?.data?.IsSuccess && response.data.Message) {
          const fileId = response.data.Message
          this.fileIds.push({
            FileId : parseInt(fileId),
            TrainingFileType: fileType
          })
        } else {
          const errorMessage = response?.data.Messages?.join(', ') || 'Unknown error occurred'
          console.error('Failed to save file:', errorMessage)
        }
      } catch (error) {
        console.error('An error occurred during file upload:', error)
      }
    },
    clearFile(type) {
      if (type === 'examResult') {
        this.examResultFile = null;
      } else if (type === 'certificate') {
        this.certificateFile = null;
      }
    },
    reloadDataTable() {
      Vue.getDatatables()
    },

    async initData() {
      const self = this
      //await self.$store.dispatch('getUserTrainingList', self.user.Id)
      if (this.userTraining.UserId) {
        const data = { userId: this.userTraining.UserId }
        api
          .getUserWorkOrderTrainingList(data)
          .then((res) => {
            this.userTrainingList = res.body.Data
          })
          .catch((error) => {
            console.error('Hata oluştu:', error)
          })
      } else {
        console.error('ID undefined veya null!')
      }
    },

    closeModal() {
      $('#user_training_modal').modal('toggle')
      this.$emit('close')
    },
    getInstructorList(selectedTrainingId) {
      const self = this
      Vue.set(self, 'instructorList', [])
      self.$nextTick(() => {
        const selectedTraining = self.trainingList.find((training) => training.Id == selectedTrainingId)
        if (selectedTraining && selectedTraining.RoleId === null) {
          Vue.set(self, 'instructorList', [{Text: selectedTraining.User.NameSurname, Value: selectedTraining.User.Id}])
        }
        else if (selectedTraining) {
          Vue.set(self, 'instructorList', self.userListByRoles[selectedTraining.RoleId])
        }
      })
    },
  },
}
