import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import * as api from '../../services/api';
import Select2 from '../../../components/shared/select2.vue';
import * as blockui from '../../../helpers/block-ui';
import * as swal from '../../../helpers/swal';

export default {
  props: {
    user: {
      default() {
        return {};
      },
      type: Object,
    },
    isUpdate: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return { };
  },
  components: {
    select2: Select2,
  },
  computed: {
    ...mapState({
        userOvertimeList: (state) => state.overtime.userOvertimeList
    }),
    ...mapGetters({
      getDocumentStatusText: 'getDocumentStatusText',
      getDocumentValidityStatusText: 'getDocumentValidityStatusText',
    }),
  },
  created() {
  },
  async mounted() {
    const self = this;
    await self.initData();
    mApp.initScrollers();
  },
  methods: {
    deleteItem(index) {
      const self = this;
      const id = self.userOvertimeList[index].Id;
      swal.check(self, 'Fazla mesaiyi silmek istediğinizden emin misiniz?').then((e) => {
        if (e.value) {
          api.deleteUserOvertime(id).then((response) => {
            if (response.body.IsSuccess) {
              toastr.success(self.translate('delete_successfully'));
              self.initData();
            } else {
              toastr.error(response.body.Message);
            }
          });
        }
      });
    },
    editItem(index) {
      this.$parent.$parent.editItem('userOvertime', this.userOvertimeList[index].Id);
    },
    async initData() {
      const self = this;
      await self.$store.dispatch('getUserOvertimeList', self.user.Id);
    }
  },
  watch: {
    userOvertimeList(e) {
      if (e) {
        Vue.set(this.$parent, 'userOvertimeCount', e.length);
      }
    },
  },
};
