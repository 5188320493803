




















































import CFMDialog from '@/scripts/CFMDialog'
import { Component, Prop, Watch } from 'vue-property-decorator';
import * as api from '../../../scripts/services/api';
import * as blockui from '@/helpers/block-ui'
import * as swal from '@/helpers/swal'
import { Item } from '@syncfusion/ej2-vue-navigations';

@Component<UserTrainingPendingModal>({
  components: {
  },
})
export default class UserTrainingPendingModal extends CFMDialog {
    @Prop()
    workOrderId?: number;
    @Prop()
    userId?: number;
    @Prop()
    userTraining?: any;
    @Prop()
    evaluationScore?: any;
    //@ts-ignore
    examResultFiles: [] = [];
    certificateFiles: [] = [];
    score = "";
    examFileId= null;
    fileIds= [] = [];
    userTrainingList: [] = [];

    defaultExamResultFiles: any[] = [];
    defaultCertificateFiles: any[] = [];
    defaultScore: any = null;

    created(): void {
        this.getWorkOrderTrainingFiles();

    }

    async getWorkOrderTrainingFiles() {
        blockui.blockModal(this);
        this.defaultExamResultFiles = [];
        this.examResultFiles = [];
        let response = (await api.workOrderTrainingFileGetAllByWorkOrderId(this.workOrderId)).body.Data;
        response.forEach((item: any) => {
            if (item.TrainingFileType === 2) {
                //@ts-ignore
                this.defaultExamResultFiles.push({
                  ...item.File,
                  ResponseId: item.Id 
              });
            }
            else if (item.TrainingFileType === 1) {
                //@ts-ignore
                this.defaultCertificateFiles.push({
                  ...item.File,
                  ResponseId: item.Id 
              });
            }
        });
        this.defaultScore = this.evaluationScore ? this.evaluationScore : '';
        blockui.unBlockModal();
    }

    uid() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }
    downloadBase64Files(file: any) {
        const link = document.createElement("a");
        link.href = `data:${file.ContentType};base64,${file.Data}`;
        link.download = file.Name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    downloadBase64File(file: any) {
    const reader = new FileReader();

    reader.onloadend = () => {
        const base64Data = reader.result as string;
        const link = document.createElement("a");
        link.href = base64Data;
        link.download = file.file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    reader.readAsDataURL(file.file);
}
    handleClickExamResultInput() {
        document.getElementById("examResultInput")?.click()
    }
    handleChangeExamResultInput(e:any){
        if(e.target.files[0]){
            //@ts-ignore
            this.examResultFiles.push({id: this.uid(), file: e.target.files[0]})
        }
    }
    handleClickexamResultFileDelete(id: any) {
        //@ts-ignore
        this.examResultFiles = this.examResultFiles.filter((item) => item.id !== id);
        
    }
    
    handleClickDefaultexamResultFileDelete(fileId: any) {
    const responseItem = this.defaultExamResultFiles.find((item) => item.Id === fileId); 
    
    if (!responseItem) {
        console.error('Silinecek öğe bulunamadı!');
        return;
    }

    const responseId = responseItem.ResponseId; 

    api.deleteWorkOrderTrainingDocument(responseId)
        .then((response: any) => {
            this.defaultExamResultFiles = this.defaultExamResultFiles.filter((item) => item.Id !== fileId);
            console.log('Dosya başarıyla silindi:', response);
        })
        .catch((error: any) => {
            console.error('Dosya silinirken hata oluştu', error);
        });
}

    handleClickCertificateInput() {
        document.getElementById("certificateInput")?.click()
    }
    handleChangeCertificateInput(e:any){
        if(e.target.files[0]){
            //@ts-ignore
            this.certificateFiles.push({id: this.uid(), file: e.target.files[0]})
        }
    }
    handleClickCertificateFileDelete(id: any) {
        //@ts-ignore
        this.certificateFiles = this.certificateFiles.filter((item) => item.id !== id);
    }
    handleClickDefaultCertificateFileDelete(fileId: any) {
        //@ts-ignore
        //this.defaultCertificateFiles = this.defaultCertificateFiles.filter((item) => item.Id !== fileId);
        const responseItem = this.defaultCertificateFiles.find((item) => item.Id === fileId); 
      if (!responseItem) {
          console.error('Silinecek öğe bulunamadı!');
          return;
      }

      const responseId = responseItem.ResponseId; 

      let deleteResponse = api.deleteWorkOrderTrainingDocument(responseId);
          this.defaultCertificateFiles = this.defaultCertificateFiles.filter((item) => item.Id !== fileId)
        
    }
    async handleFileChange(event: any, type: any) {
    if (event.target.files[0] && (type === 'examResult' || type === 'certificate')) {
        const files = Array.from(event.target.files);
        const fileType = type === 'examResult' ? 2 : type === 'certificate' ? 1 : null;

        if (!fileType) return;

        for (const file of files) {
            const fileData = {
                id: this.uid(),
                file: file,
                type,
            };

            if (type === 'examResult') {
                //@ts-ignore
                this.examResultFiles.push(fileData);
            } else if (type === 'certificate') {
                //@ts-ignore
                this.certificateFiles.push(fileData);
            }

            try {
                //@ts-ignore
                const response = await this.$globalApiClient.file.fileSaveFile({ file: fileData.file, type: fileType });

                if (response?.data?.IsSuccess && response.data.Message) {
                    const fileId = response.data.Message;

                    //@ts-ignore
                    if (!this.fileIds) {
                        //@ts-ignore
                        this.fileIds = {};
                    }
                    //@ts-ignore
                    this.fileIds[fileData.id] = parseInt(fileId);
                } else {
                    const errorMessage = response?.data.Messages?.join(', ') || 'Unknown error occurred';
                    console.error('Failed to save file:', errorMessage);
                }
            } catch (error) {
                console.error('An error occurred during file upload:', error);
            }
        }
    }
}

async handleSubmit() {
    const self = this;
    blockui.blockModal(this);

    const requestObject = {
        WorkOrderId: this.workOrderId,
        StatusId: 5,
        EvaluationScore: this.defaultScore !== null ? this.defaultScore : this.score,
        WorkOrderTrainingFiles: [
            ...this.examResultFiles.map((item) => ({
                TrainingFileType: 2,
                WorkOrderId: this.workOrderId,
                //@ts-ignore
                FileId: this.fileIds[item.id] || null,
            })),
        
            ...this.certificateFiles.map((item) => ({
                TrainingFileType: 1,
                WorkOrderId: this.workOrderId,
                //@ts-ignore
                FileId: this.fileIds[item.id] || null, 
            })),
            // Varsayılan examResult dosyaları
            ...this.defaultExamResultFiles.map((item) => ({
                TrainingFileType: 2,
                WorkOrderId: this.workOrderId,
                FileId: item.Id,
            })),
            // Varsayılan certificate dosyaları
            ...this.defaultCertificateFiles.map((item) => ({
                TrainingFileType: 1,
                WorkOrderId: this.workOrderId,
                FileId: item.Id,
            })),
        ],
    };

    try {
        const response = await api.approveWorkOrderTraining(requestObject);

        if (response?.data?.IsSuccess) {
            this.saveSucceeded();
            await self.$store.dispatch('getUserWorkOrderTrainingList', { userId: this.userId });
            this.close();
        } else {
            console.error('Failed to submit:', response?.data?.Messages?.join(', ') || 'Unknown error occurred');
        }
    } catch (error) {
        console.error('An error occurred during submission:', error);
    } finally {
        blockui.unBlockModal();
    }
}


}
