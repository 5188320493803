import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import * as api from '../../services/api'
import Select2 from '../../../components/shared/select2'
import * as blockui from '../../../helpers/block-ui'
import * as swal from '../../../helpers/swal'
import { Page } from '@syncfusion/ej2-vue-grids'

export default {
  props: {
    id: {
      default: null,
      type: Number,
    },
    user: {
      default() {
        return {}
      },
      type: Object,
    },
    isUpdate: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      filter: {
        filterTraining: '',
      },
      gridKey: 0,
      pageSettings: { pageSizes: [50, 100, 200], pageCount: 4 },
    }
  },
  provide: {
    grid: [Page],
  },
  components: {
    select2: Select2,
  },
  computed: {
    ...mapState({
      // referenceLibraryList: state => state.reference_library.referenceLibraryList,
      // timeUnitList: state => state.unit.timeUnitList,
      //userTrainingList: (state) => state.training.userTrainingList,
      trainingList: (state) => state.training.trainingList,
      userList: (state) => state.user.userList,
      userTrainingList: (state) => state.training.UserWorkOrderTrainingList,
    }),
    ...mapGetters({
      getDocumentStatusText: 'getDocumentStatusText',
      getDocumentValidityStatusText: 'getDocumentValidityStatusText',
    }),
  },
  created() {
    const self = this
    self.$store.commit('setUserTrainingFilter', {})
  },
  async mounted() {
    const self = this
    self.$store.dispatch('getTrainingList')
    self.$store.dispatch('getUserList')

    self.$store.dispatch('getUserWorkOrderTrainingList', { userId: this.id })
    // self.$store.dispatch('getReferenceLibraryList', {deviceId: self.user.Id});

    /* self.$store.dispatch('getTimeUnitList');
    self.$store.dispatch('getDocumentStatuses');
    self.$store.dispatch('getDocumentValidityStatuses'); */
    mApp.initScrollers()
  },
  methods: {
    idTemplate() {
      return {
        template: Vue.component('idTemplate', {
          template: `
            <div>
              <span v-if="!data.IsManuel">{{ data.Id }}</span>
            </div>
          `,
        }),
      }
    },
    
    certificateTemplate() {
      return {
        template: Vue.component('certificateColumnTemplate', {
          template: `
            <div>
              <!-- Sertifika dosyalarını alt alta listeleme -->
              <div 
                v-for="file in data.UserTrainingFiles" 
                :key="file.File.Name" 
                v-if="file.TrainingFileType === 1"
                style="margin-bottom: 8px;"
              >
                <a 
                  href="#"
                  @click.prevent="downloadFile(file.File.Name, file.File.Data)"
                  style="cursor: pointer; color: blue; text-decoration: underline;"
                >
                  {{ file.File.Name }}
                </a>
              </div>
            </div>
          `,
          methods: {
            downloadFile(fileName, base64Content) {
              if (!base64Content) {
                alert('Bu dosya indirilemez (Base64 içeriği yok).')
                return
              }
              const binaryString = atob(base64Content)
              const len = binaryString.length
              const bytes = new Uint8Array(len)
              for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i)
              }
              const blob = new Blob([bytes.buffer], {
                type: 'application/octet-stream',
              })
              const url = URL.createObjectURL(blob)
              const link = document.createElement('a')
              link.href = url
              link.download = fileName
              link.click()
              URL.revokeObjectURL(url)
            },
          },
        }),
      }
    },

    resultDocumentTemplate() {
      return {
        template: Vue.component('resultDocumentColumnTemplate', {
          template: `
            <div>
              <!-- Sonuç belgesi dosyalarını alt alta listeleme -->
              <div 
                v-for="file in data.UserTrainingFiles" 
                :key="file.File.Name" 
                v-if="file.TrainingFileType === 2"
                style="margin-bottom: 8px;"
              >
                <a 
                  href="#"
                  @click.prevent="downloadFile(file.File.Name, file.File.Data)"
                  style="cursor: pointer; color: blue; text-decoration: underline;"
                >
                  {{ file.File.Name }}
                </a>
              </div>
            </div>
          `,
          methods: {
            downloadFile(fileName, base64Content) {
              if (!base64Content) {
                alert('Bu dosya indirilemez (Base64 içeriği yok).')
                return
              }
              const binaryString = atob(base64Content)
              const len = binaryString.length
              const bytes = new Uint8Array(len)
              for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i)
              }
              const blob = new Blob([bytes.buffer], {
                type: 'application/octet-stream',
              })
              const url = URL.createObjectURL(blob)
              const link = document.createElement('a')
              link.href = url
              link.download = fileName
              link.click()
              URL.revokeObjectURL(url)
            },
          },
        }),
      }
    },

    actionButtonsTemplate() {
      return {
        template: Vue.component('actionButtonsTemplate', {
          template: `
            <div>
              <button v-if="data.IsManuel" class="btn btn-icon" @click="deleteItem(data.Id)">
                <i class="la la-trash"></i>
              </button>
              <button class="btn btn-icon" @click="editItem(data)">
                <i class="la la-edit"></i>
              </button>
            </div>
          `,
          methods: {
            deleteItem(id) {
              const self = this
              const userId = this.$parent.$parent.id;
              api.deleteUserTraining(id).then((response) => {
                if (response.body.IsSuccess) {
                  toastr.success(self.translate('delete_successfully'))
                  self.$store.dispatch('getUserWorkOrderTrainingList', { userId: userId })
                } else {
                  toastr.error(response.body.Message)
                }
              })
            },

            editItem(data) {
              if (!data.IsManuel) {
                this.$parent.$parent.$parent.$parent.editItem('workOrderTraining', data.Id)
              } else {
                this.$parent.$parent.$parent.$parent.editItem('userTraining', data.Id)
              }
            },
          },
        }),
      }
    },

    processBase64(base64) {
      if (base64) {
        console.log('Processing Base64:', base64)
      } else {
        console.warn('No Base64 content available')
      }
    },
    getFileNames(data) {
      return data.UserTrainings.flatMap((training) => training.UserTrainingFiles.map((file) => file.File.Name)).join(
        ', '
      )
    },
    // deleteItem(index) {
    //   const self = this;
    //   const id = self.userTrainingList[index].Id;
    //   const name = self.userTrainingList[index].Name;
    //   swal.check(self, name).then((e) => {
    //     if (e.value) {
    //       api.deleteUserTraining(id).then((response) => {
    //         if (response.body.IsSuccess) {
    //           toastr.success(self.translate('delete_successfully'));
    //           self.initData();
    //         } else {
    //           toastr.error(response.body.Message);
    //         }
    //       });
    //     }
    //   });
    // },
    // editItem(index) {
    //   this.$parent.$parent.editItem('userTraining', this.userTrainingList[index].Id);
    // },

    onFilter(e) {
      const self = this
      e.preventDefault()
      const form = $(e.target)
      if (form.valid()) {
        const formData = {}
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value })
        })
        self.$store.commit('setUserTrainingFilter', formData)
        self.$store.dispatch('getUserWorkOrderTrainingList', { userId: this.id })
      }
    },
  },
  watch: {
    userTrainingList(e) {
      if (e) {
        Vue.set(this.$parent, 'userTrainingCount', e.length)
      }
    },
  },
}
