import { render, staticRenderFns } from "./UserDocumentationModal2.vue?vue&type=template&id=6cf243ce&scoped=true&"
import script from "../../../scripts/partials/UserDocumentationModal2.js?vue&type=script&lang=js&"
export * from "../../../scripts/partials/UserDocumentationModal2.js?vue&type=script&lang=js&"
import style0 from "./UserDocumentationModal2.vue?vue&type=style&index=0&id=6cf243ce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cf243ce",
  null
  
)

export default component.exports