module.exports = {
  template: '<textarea :name="name"></textarea>',

  props: {
    value: {
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: '150',
    },
    apiClient: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isUploading: false, // Track upload state
    }
  },

  mounted() {
    const config = {
      height: this.height,
      dialogsInBody: true,
    }

    const vm = this

    config.callbacks = {
      onInit() {
        $(vm.$el).summernote('code', vm.computedValue)
        vm.addGlobalClickListener()
      },
      onChange() {
        vm.$emit('input', encodeURIComponent($(vm.$el).summernote('code')))
      },
      onBlur() {
        vm.$emit('input', encodeURIComponent($(vm.$el).summernote('code')))
      },
      async onImageUpload(files) {
        if (this.isUploading) return
        this.isUploading = true

        for (const file of files) {
          const fileSizeMB = file.size / 1024 / 1024
          if (fileSizeMB > 20) {
            alert("Dosya boyutu 20 MB'yi geçmemeli.")
            continue
          }

          const formData = new FormData()
          formData.append('file', file)

          try {
            console.log('Starting file upload...')
            const response = await vm.apiClient.file.fileSaveFile({ file: file }, { id: 0 })

            let fileUrl
            if (response.data && response.data.IsSuccess) {
              const fileId = response.data.Data?.id || response.data.Message
              if (fileId) {
                fileUrl = `${vm.apiClient.baseUrl}/File/GetFile?id=${fileId}&isContainsData=true`

                // Use a data attribute to store file ID and add event listener later
                const link = `<a href="javascript:;" data-file-id="${fileId}" class="download-link">${file.name}</a>`
                $(vm.$el).summernote('pasteHTML', link)

                // Attach click event to download link
                setTimeout(() => {
                  document.querySelectorAll('.download-link').forEach((el) => {
                    el.addEventListener('click', (event) => {
                      const fileId = event.target.getAttribute('data-file-id')
                      if (fileId) {
                        vm.downloadFile(fileId)
                      }
                    })
                  })
                }, 100) // Delay to ensure the link is rendered in the DOM
              } else {
                alert("Geçersiz dosya ID'si döndü.")
              }
            } else {
              const errorMessage = response.data.Message || 'Dosya yükleme sırasında bir hata oluştu.'
              console.error('Upload Error:', errorMessage)
              alert(errorMessage)
            }
          } catch (error) {
            console.error('Yükleme hatası:', error)
            alert('Dosya yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.')
          } finally {
            this.isUploading = false
          }
        }
      },
    }

    $(this.$el).summernote(config)
  },

  methods: {
    addGlobalClickListener() {
      const vm = this

      // Önce eski dinleyiciyi kaldır
      $(document).off('click', '.download-link')

      // Yeni dinleyiciyi ekle
      $(document).on('click', '.download-link', function (event) {
        const fileId = $(this).data('file-id')
        if (fileId) {
          vm.downloadFile(fileId)
        }
      })
    },

    async downloadFile(fileId) {
      try {
        const response = await this.apiClient.file.fileGetFile({ id: fileId, isContainsData: true })
        if (
          response.data.IsSuccess &&
          response.data.Data &&
          response.data.Data.Base64 &&
          response.data.Data.ContentType
        ) {
          this.downloadBlob(
            this.base64toBlob(response.data.Data.Base64, response.data.Data.ContentType),
            response.data.Data.Name
          )
        } else {
          alert('Dosya indirilirken bir hata oluştu.')
        }
      } catch (error) {
        console.error('Download error:', error)
        alert('Dosya indirilirken bir hata oluştu.')
      }
    },

    base64toBlob(base64Data, type) {
      const byteString = atob(base64Data)
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ia], { type: type })
    },

    downloadBlob(blob, fileName) {
      const a = document.createElement('a')
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    },
  },

  computed: {
    computedValue() {
      return this.value ? decodeURIComponent(this.value) : ''
    },
  },

  watch: {
    value() {
      const isSame = $(this.$el).summernote('code') === this.computedValue
      if (!isSame) {
        $(this.$el).summernote('code', this.computedValue)
      }
    },
  },
}
