import Vue from 'vue';
import * as api from '../services/api';
import Select2 from '../../components/shared/select2.vue';
import * as blockui from '../../helpers/block-ui';

export default {
  props: ['userDocumentation', 'isUpdate',],
  data() {
    return {
      file: null,
      fileDescription: '',
      userDocumentList: [],
      userDocumentationCount: 0,
    };
  },
  components: {
    select2: Select2,
  },
  created() {
    
  },
  mounted() {
    this.initValidation();
  },
  methods: {
    initValidation() {
      const form = window.$('#user-documentation-form');
      if (form.length) {
        form.validate();
      } else {
        console.error("Form bulunamadı!");
      }
    },
    handleFileChange(event) {
      const selectedFile = event.target.files[0];

      if (selectedFile) {
        if (selectedFile.size > 20 * 1024 * 1024) {
          alert('Dosya boyutu 20 MB\'ı geçemez.');
          event.target.value = '';
          return;
        }

        this.file = {
          file: selectedFile, 
          name: selectedFile.name,
          size: selectedFile.size,
          type: selectedFile.type,
          preview: selectedFile.type.includes('image/') ? URL.createObjectURL(selectedFile) : null
        };
      }
    },  
    removeFile() {
      this.file = null;
    },

    async onSubmit() {
      const self = this;
      if (!self.file) {
        alert('Lütfen bir dosya seçin.');
        return;
      }
      const formData = new FormData();
      formData.append('UserId', this.userDocumentation.UserId);
      formData.append('file', this.file.file);
      formData.append('description', this.fileDescription);
      
      blockui.blockModal(this);
      api.saveUserDocumentation(formData).then((response) => {
        if (response.body.IsSuccess) {
          toastr.success(self.translate('save_successfully'));
          self.$store.dispatch('getUserDocumentList', { userId:  this.userDocumentation.UserId });
          blockui.unBlockModal();
          self.closeModal();

        } else {
          toastr.error(response.body.Message);
        }

      }, (error) => {
        blockui.unBlockModal();
      });
    },
      reloadDataTable() {
       Vue.getDatatables();
    },
    closeModal() {
      $('#user-documentation-modal').modal('toggle');
      this.$emit('close');
    },
    // initData() {
    //     api.getUserDocumentList({ userId: this.userDocumentation.UserId }).then((res) => {
    //         this.userDocumentList = res.body;
    //     });

    // }
  },
  watch: {
    userDocumentList(e) {
      if (e) {
        Vue.set(this.$parent, 'userDocumentationCount', e.length);
      }
    },
  }
};