



























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DataStateChangeEventArgs } from '@syncfusion/ej2-vue-grids'
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations'
import CFMGridPage from '@/scripts/CFMGridPage'
import { Predicate } from '@syncfusion/ej2-data'

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'

import { CFMDTOWorkOrder } from '@/scripts/services/ClientApiAuto'
import moment from 'moment'
import { L10n } from '@syncfusion/ej2-base'

@Component<WorkOrder>({})
export default class WorkOrder extends CFMGridPage<CFMDTOWorkOrder> {
  @Prop()
  startDate?: string
  @Prop()
  endDate?: string
  @Prop()
  workOrderStatusId?: string

  entityType = 'WorkOrder'
  sorts: { field: string; direction: string }[] = [{ field: 'Id', direction: 'Descending' }]
  headerText = ''

  entityState: DataStateChangeEventArgs = {
    where: [
      new Predicate('isActive', 'equal', true, false),
      new Predicate('OrderSourceId', 'notequal', 3, false),
      new Predicate('OrderSourceId', 'notequal', 4, false),
    ],
  }
  pageReady = false
  async created() {
    const columns = []
    if (this.workOrderStatusId) {
      const selectedWorkOrderStatus = await this.apiClient.workOrderStatus.workOrderStatusGetById({
        id: parseInt(this.workOrderStatusId),
      })
      columns.push({
        field: 'OrderStatusName',
        matchCase: true,
        operator: 'equal',
        value: selectedWorkOrderStatus.data.Name,
        predicate: 'and',
      })
    }
    if (this.startDate && this.endDate) {
      const startDate = moment(parseInt(this.startDate)).toISOString()
      const endDate = moment(parseInt(this.endDate)).toISOString()
      columns.push(
        { field: 'ScheduledTime', matchCase: true, operator: 'greaterthan', value: startDate, predicate: 'and' },
        { field: 'ScheduledTime', matchCase: true, operator: 'lessthan', value: endDate, predicate: 'and' }
      )
    }
    this.filterSettings = { type: 'Menu', columns: columns }
    this.createdHook()
    this.pageReady = true
    await this.$nextTick()
    this.dataStateChange(this.state)
    this.querCellInfo(this)
    L10n.load({
      tr: {
        grid: {
          FilterButton: 'Filtrele',
          ClearButton: 'Temizle',
        },
      },
    })
  }
  refreshGrid() {
    this.dataStateChange(this.state)
  }
  customiseCell(args: any) {
    if (args.column.field === 'OrderStatusName') {
      args.cell.classList.add('bg-color')
    }
  }
  querCellInfo(args: any) {
    if (args.column) {
      if (args.column.field === 'OrderStatusName') {
        let value = args.data['ColorCode']
        args.cell.style.backgroundColor = value
        return
      }
    }
  }
  recordDoubleClick(args: any) {
    this.edit(args.rowData.Id, args.rowData.OrderStatusId)
  }
  async delete(data: CFMDTOWorkOrder) {
    //@ts-ignore
    const e = await swal.check(this, data.Id)
    if (e.value) {
      blockui.pageBlock(this)
      const response = await this.apiClient.workOrder.workOrderDelete({ id: data.Id })
      if (response.data.IsSuccess) {
        toastr.success(this.translate('delete_successfully'))
        this.refreshGrid()
      } else {
        const errorMessage = response.data.Message ? response.data.Message : 'Error'
        toastr.error(errorMessage)
      }
      blockui.unBlockPage()
    }
  }
  async edit(id: number, orderStatusId: number) {
    if (orderStatusId == 1 || orderStatusId == 2) {
      const router = {
        name: 'AddWorkOrder',
        query: {
          workOrderId: String(id),
        },
      }
      const routeData = this.$router.resolve(router)
      window.open(routeData.href, '_blank')
    } else {
      swal.error(this.translate('edit_maintenance_plan_error'))
    }
  }
  async add() {
    const router = {
      name: 'AddWorkOrder',
    }
    const routeData = this.$router.resolve(router)
    window.open(routeData.href, '_blank')
  }
  async toolbarClick(args: ClickEventArgs) {
    var selectedRecords = (<any>this.$refs.grid).getSelectedRecords()
    if (args.item.id === 'Edit') {
      if (selectedRecords.length > 0) {
        this.edit(selectedRecords[0].Id, selectedRecords[0].OrderStatusId)
      }
    } else if (args.item.id === 'Add') {
      this.add()
    } else if (args.item.id === 'Delete') {
      if (selectedRecords.length > 0) {
        this.delete(selectedRecords[0])
      }
    }
    this.toolbarClickBase(args)
  }
  commandTemplate() {
    return {
      template: Vue.component('columnTemplate', {
        template: `
                    <div>
                        <router-link
                            :to="{ name: 'MyWorkOrder', query: { workOrderId: data.Id }}"
                            v-slot="{ href, navigate }"
                            custom >
                            <a :href="href" @click="navigate" :title="translate('show')" class="e-lib e-btn e-control e-icon-btn">
                                <span class="e-btn-icon e-icons e-preview-icon"></span>
                            </a>
                        </router-link>
                    </div>
                    `,
      }),
    }
  }
}
