




































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations'
import CFMGridPage from '@/scripts/CFMGridPage'

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'
import * as api from '../../../../scripts/services/api'

import { CFMModelWorkOrderView, CFMDTOMaintenancePlan } from '@/scripts/services/ClientApiAuto'
import TrainingAsignmentModal from '../../WorkOrder/TrainingAsignment/TrainingAsignmentModal.vue'

import { mixinz } from '@/scripts/global/mixinz'
import moment from 'moment'
import { L10n } from '@syncfusion/ej2-base'

@Component<TrainingAsignment>({
  components: {
    'training-asignment-modal': TrainingAsignmentModal,
  },
  mixins: [mixinz],
})
export default class TrainingAsignment extends CFMGridPage<CFMModelWorkOrderView> {
  entityType = 'WorkOrder'
  quickWorkOrder?: boolean
  selectedTrainingPlans: Array<CFMDTOMaintenancePlan> | null = null
  sorts: { field: string; direction: string }[] = [{ field: 'Id', direction: 'Descending' }]
  // @ts-ignore
  filterSettings = {
    type: 'Menu',
    // @ts-ignore
    columns: !this.isContractPage
      ? [{ field: 'OrderSourceId', matchCase: false, operator: 'equal', predicate: 'and', value: 4 }]
      : [],
  }

  async created() {
    L10n.load({
      tr: {
        grid: {
          FilterButton: 'Filtrele',
          ClearButton: 'Temizle',
        },
      },
    })
  }

  async getContractPageRecords() {
    // @ts-ignore
    api.getDeviceListByContractId(this.contractId).then((res) => {
      // @ts-ignore
      let newFilter = res.body.map((capacityEquipment) => {
        // @ts-ignore
        return {
          field: 'DeviceName',
          matchCase: false,
          operator: 'equal',
          predicate: 'or',
          value: capacityEquipment.Text,
        }
      })
      this.filterSettings = {
        type: 'Menu',
        columns: newFilter,
      }
    })
  }

  async mounted() {
    this.dataStateChange(this.state)
  }

  refreshGrid() {
    this.dataStateChange(this.state)
  }

  customiseCell(args: any) {
    // Added status column
    if (args.column.field === 'OrderStatusName') {
      args.cell.classList.add('bg-color')
    }
  }

  queryCellInfo(args: any) {
    // Added status column
    if (args.column) {
      if (args.column.field === 'OrderStatusName') {
        let value = args.data['ColorCode']
        args.cell.style.backgroundColor = value
        return
      }
    }
  }

  commandTemplate() {
    return {
      template: Vue.component('columnTemplate', {
        template: `
                    <div>
                        <router-link
                            :to="{ name: 'WorkOrderTraining', query: { workOrderId: data.Id }}"
                            v-slot="{ href, navigate }"
                            custom >
                            <a :href="href" @click="navigate" :title="translate('show')" class="e-lib e-btn e-control e-icon-btn">
                                <span class="e-btn-icon e-icons e-preview-icon"></span>
                            </a>
                        </router-link>
                    </div>
                    `,
      }),
    }
  }

  recordDoubleClick(args: any) {
    this.edit([args.rowData])
  }

  async delete(dataList: CFMDTOMaintenancePlan[]) {
    // @ts-ignore
    const e = await swal.check(this, dataList.map((data) => data.Id).toString())
    if (e.value) {
      dataList.forEach((data) => {
        // @ts-ignore
        if (!data.IsDeletable) {
          toastr.error('Sadece Planlandı ve Atama Yapıldı statüsündeki silinebilir.')
        }
      })
      // @ts-ignore
      const selectedId = dataList.find((data) => data.IsDeletable)?.Id
      // @ts-ignore
      if (selectedId) {
        blockui.pageBlock(this)
        try {
          const response = await this.apiClient.workOrder.workOrderDelete({ id: selectedId })
          if (response.data.IsSuccess) {
            const successMessage = response.data.Message || 'Succes'
            toastr.success(successMessage)
            this.refreshGrid()
          } else {
            const errorMessage = response.data.Message || 'Error'
            toastr.error(errorMessage)
          }
        } catch (error) {
          toastr.error('An error occurred while deleting.')
        } finally {
          blockui.unBlockPage()
        }
      }
    }
  }

  async edit(workOrders: any) {
    const invalidWorkOrder = workOrders.some(
      (workOrder: any) => workOrder.OrderStatusId !== 1 && workOrder.OrderStatusId !== 2
    )

    if (invalidWorkOrder) {
      swal.error(this.translate('edit_maintenance_plan_error'))
      return
    }
    if (workOrders.length === 1) {
      this.selectedId = workOrders[0].Id
      this.isDialogActive = true
      await this.$nextTick()
      ;(<TrainingAsignmentModal>this.$refs.trainingAsignmentModal).showDialog()
    } else {
      if (this.getDistinctWorkGroupId(workOrders) !== 1) {
        blockui.unBlockPage()
        toastr.error(this.translate('cannot_transform_different_work_group'))
        return
      }
      this.selectedTrainingPlans = workOrders
      await this.$nextTick()
    }
  }
  async add() {
    this.selectedId = null
    this.isDialogActive = true
    await this.$nextTick()
    ;(<TrainingAsignmentModal>this.$refs.trainingAsignmentModal).showDialog()
  }

  async transformToWorkOrder(dataList: Array<CFMDTOMaintenancePlan>, isNewPage: boolean) {
    const self = this
    let statusError = false
    dataList.forEach((data) => {
      // @ts-ignore
      if (data.OrderStatusId !== 1 && data.OrderStatusId !== 2) {
        statusError = true
      }
    })
    if (statusError) {
      toastr.error('Sadece Planlandı ve Atama Yapıldı statüsündeki bakımlara atama yapılabilir')
    } else {
      blockui.pageBlock(this)
      if (dataList.length === 1) {
        blockui.unBlockPage()
        const router = { name: 'AddWorkOrder', query: { workOrderId: dataList[0].Id ? dataList[0].Id.toString() : '' } }
        const routeData = this.$router.resolve(router)
        window.open(routeData.href, '_blank')
      } else {
        if (self.getDistinctWorkGroupId(dataList) !== 1) {
          blockui.unBlockPage()
          toastr.error(self.translate('cannot_transform_different_work_group'))
          return
        } else {
          blockui.unBlockPage()
          this.selectedTrainingPlans = dataList
          await this.$nextTick()
        }
      }
    }
  }

  getDistinctWorkGroupId(data: Array<CFMDTOMaintenancePlan>) {
    return data.map((x) => x.WorkGroupId).filter((value, index, array) => array.indexOf(value) === index).length
  }

  async show(data: CFMDTOMaintenancePlan) {
    this.$router.push({
      name: 'MyWorkOrder',
      query: { from: 'WorkOrderList', workOrderId: data.Id ? data.Id.toString() : '' },
    })
  }

  async toolbarClick(args: ClickEventArgs) {
    var selectedRecords = (<any>this.$refs.grid).getSelectedRecords()
    if (args.item.id === 'Edit') {
      if (selectedRecords.length > 0) {
        this.edit(selectedRecords)
      }
    } else if (args.item.id === 'Add') {
      this.add()
    } else if (args.item.id === 'Delete') {
      if (selectedRecords.length > 0) {
        this.delete(selectedRecords)
      }
    } else if (args.item.id === 'TransformToWorkOrder') {
      const selectedRecord = selectedRecords.length > 0
      if (selectedRecord) {
        this.transformToWorkOrder(selectedRecords, false)
      }
    } else if (args.item.id === 'Show') {
      if (selectedRecords.length > 1) {
        toastr.error('Bu işlem için tek bir seçim yapmalısınız')
        return
      }
      if (selectedRecords.length > 0) {
        const router = {
          name: 'MyWorkOrder',
          query: {
            from: 'WorkOrderList',
            workOrderId: selectedRecords[0].Id ? selectedRecords[0].Id.toString() : '',
          },
        }
        const routeData = this.$router.resolve(router)
        window.open(routeData.href, '_blank')
      }
    }
    this.toolbarClickBase(args)
  }
}
