






























import { Component, Prop, Vue} from 'vue-property-decorator';
import { mixinz } from '@/scripts/global/mixinz';
import CFMGridPage from '@/scripts/CFMGridPage';
import * as blockui from '@/helpers/block-ui'
import * as api from '../../scripts/services/api';
import PendingTrainingApproveDialog from './PendingTrainingsApproveDialog.vue';



@Component<any>({
    
    components: {
        "pending-training-approve-dialog": PendingTrainingApproveDialog
    },
    mixins: [mixinz]
})
export default class MaintenancePlan extends CFMGridPage<any> {
    entityType = "PendingTrainingApproval";
    entityState = {where: []}

    commandPreview =[{buttonOption: {iconCss:"e-icons e-preview-icon" }, title:'Ön İzleme'}]
    commandSendBack =[{buttonOption: {iconCss:"e-icons e-send-back-icon" },title:'Geri Gönder'}]
    commandApprove =[{buttonOption: {iconCss:"e-icons e-approve-icon" },title:'Onayla'}]
    commandReject =[{buttonOption: {iconCss:"e-icons e-reject-icon" },title:'Reddet'}]

    forApproveWorkOrderId: number | null = null;

    async mounted(){
        this.dataStateChange(this.state);
    }
    async commandClick(args:any)
    {
        if (args.target.children[0].classList.contains("e-send-back-icon")) {
            blockui.pageBlock(this);
            const res = await this.apiClient.workOrder.workOrderSendBackFromPendingApproval({workOrderId:args.rowData.Id})
            if (res.data.IsSuccess) {
                toastr.success(this.translate('sent_back'));
                this.refreshGrid();
            } else {
                const errorMessage = res.data.Message ? res.data.Message : "Error";
                toastr.error(errorMessage);
            }
            blockui.unBlockPage();
        }
        else if (args.target.children[0].classList.contains("e-approve-icon")) {
            this.isDialogActive = true;
            this.forApproveWorkOrderId = args.rowData.Id
            await this.$nextTick();
            (<PendingTrainingApproveDialog>this.$refs.pendingTrainingApproveDialog).showDialog();
        }
         else if (args.target.children[0].classList.contains("e-reject-icon")) {
            let response = await api.approveWorkOrder({
                Id: args.rowData.Id,
                WorkOrderStatusDescriptionId: 2,
                StatusId: 8
            })
            if(response.data.IsSuccess){
                toastr.success(this.translate('declined'));
                this.refreshGrid();
            }
        }
        else if (args.target.children[0].classList.contains("e-preview-icon")) {
            window.open(`/#/WorkOrderTraining?workOrderId=${args.rowData.Id}`, '_blank');
        }
    }
}
